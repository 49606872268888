
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import LoaderComponent from '@/app/components/particles/Loader.vue';
import TwoFactorAuthModal from '@/app/components/modals/variants/TwoFactorAuthModal.vue';

export default defineComponent({
  name: 'App',
  components: { TwoFactorAuthModal, LoaderComponent },
  computed: {
    ...mapGetters({
      isAuthenticated: 'account/isAuthenticated'
    })
  }
});
