import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "fixed inset-0 flex items-center justify-center p-4 bg-black/30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_DialogDescription = _resolveComponent("DialogDescription")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    show: _ctx.isOpen,
    as: "template"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        class: "relative z-50",
        onClick: _ctx.closeModal
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TransitionChild, {
            enter: "duration-300 ease-out",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "duration-200 ease-in",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_TransitionChild, {
                  enter: "duration-300 ease-out",
                  "enter-from": "-translate-y-full",
                  "enter-to": "translate-y-0",
                  leave: "duration-200 ease-in",
                  "leave-from": "opacity-100",
                  "leave-to": "opacity-0",
                  class: _normalizeClass(["max-md:w-full", `w-${_ctx.sizeClassPart}`])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DialogPanel, { class: "modal-dialog-panel rounded-lg bg-white p-8 grid gap-4 overflow-y-auto" }, {
                      default: _withCtx(() => [
                        (_ctx.title)
                          ? (_openBlock(), _createBlock(_component_DialogTitle, {
                              key: 0,
                              class: "text-2xl uppercase"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.title), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.description)
                          ? (_openBlock(), _createBlock(_component_DialogDescription, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.description), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _renderSlot(_ctx.$slots, "default")
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                }, 8, ["class"])
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["onClick"])
    ]),
    _: 3
  }, 8, ["show"]))
}