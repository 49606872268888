import { Exclude, Expose, Transform, TransformFnParams } from 'class-transformer';
import { AbstractModel } from '@/app/typings/models/_base.model';
import { Image } from '@/app/typings/models/image.model';
import { convertToModel } from '@/app/misc/helpers/model-conversion/convert-to-model.function';

@Exclude()
export class Account extends AbstractModel {
  @Expose() id: number;
  @Expose() email: string;
  @Expose({ name: 'phone_verified' }) phoneVerified: boolean;
  @Expose({ name: 'handcash_handle' }) handcashHandle: string;
  @Expose({ name: 'company_name' }) companyName: string;
  @Expose({ name: 'mobile_phone' }) phone: string;
  @Expose({ name: 'first_name' }) firstName: string;
  @Expose({ name: 'last_name' }) lastName: string;
  @Expose({ name: 'handcash_code_verified' }) handcashCodeVerified: boolean;
  @Expose({ name: 'handcash_request_id' }) handcashRequestId: string;
  @Expose({ name: 'handcash_account_creation_id' }) handcashAccountCreationId: string;

  @Expose({ name: 'image_key' })
  @Transform(({ obj }: TransformFnParams): Image => convertToModel({ key: obj.image_key, url: obj.image_url }, Image))
  image: Image;
  @Expose()
  @Transform(({ obj }: TransformFnParams): string=> `${obj?.first_name ?? ''} ${obj?.last_name ?? ''}`.trim())
  fullName: string;
}
