
import { defineComponent, PropType } from 'vue';
import { IRange } from '@/app/typings/interfaces/range.interface';

interface IData {
  range: IRange;
}

export default defineComponent({
  name: 'BaseRange',
  emits: {
    ['update:modelValue']: (value: IRange): boolean => Boolean(value),
    input: (event: InputEvent): boolean => Boolean(event)
  },
  props: {
    modelValue: Object as PropType<IRange>,
    inputColor: {
      type: String as PropType<'gray-100' | 'white'>,
      default: 'gray-100'
    }
  },
  watch: {
    modelValue(value: IRange): void {
      this.range = value;
    }
  },
  data(): IData {
    return {
      range: {
        from: null,
        to: null
      }
    };
  },
  methods: {
    filterByDate(key: 'from' | 'to', event: Event): void {
      const input: HTMLInputElement = event.target as HTMLInputElement;
      this.range[key] = input.value;
      this.$emit('update:modelValue', this.range);
      this.$emit('input', event as InputEvent);
    }
  }
});
