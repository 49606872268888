
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle, DialogDescription } from '@headlessui/vue';
import { defineComponent, PropType } from 'vue';

type size = 'xl' | 'lg' | 'md' | 'sm' | 'full' | 'fit' | 'auto';

export default defineComponent({
  name: 'ModalComponent',
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    size: {
      type: String as PropType<size>,
      required: false,
      default: 'md'
    }
  },
  computed: {
    sizeClassPart(): size {
      return ['xl', 'lg', 'md', 'sm', 'full', 'fit', 'auto'].includes(this.size) ? this.size : 'sm';
    }
  },
  methods: {
    closeModal(): void {
      this.$emit('close-modal');
    }
  }
});
