import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "filter flex flex-col pb-8 gap-y-4" }
const _hoisted_2 = { class: "flex flex-col md:flex-row gap-4" }
const _hoisted_3 = {
  key: 0,
  class: "relative grow"
}
const _hoisted_4 = {
  key: 0,
  class: "filter-results flex justify-between max-md:flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_range = _resolveComponent("base-range")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_range, {
        modelValue: _ctx.range,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.range) = $event)),
        "input-color": "white",
        onInput: _ctx.filterByDate
      }, null, 8, ["modelValue", "onInput"]),
      (!_ctx.withoutSearch)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_base_input, {
              class: "w-full",
              placeholder: "Search...",
              "input-color": "white",
              type: "search"
            }),
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-solid fa-search",
              class: "absolute left-4 top-4 text-gray-500"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isFiltered)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "text-cyan hover:text-cyan-700 font-bold max-md:mb-3",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearFilter && _ctx.clearFilter(...args)))
          }, "Clear filter results"),
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}