import { AbstractApiBaseService } from '@/app/misc/abstract/api-service.abstract';
import { PaymentRequest } from '@/app/typings/models/payment-request.model';
import { ClassConstructor } from 'class-transformer';

export class PaymentRequestsApiService extends AbstractApiBaseService<PaymentRequest> {
  protected readonly _MODEL: ClassConstructor<PaymentRequest> = PaymentRequest;
  protected readonly _URL_PATH: string = '/paymentrequests';
}

export const paymentRequestsApi: PaymentRequestsApiService = new PaymentRequestsApiService();
