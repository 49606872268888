import { IAccountState } from '@/app/typings/interfaces/store/account-state.interface';
import { storage } from '@/app/services/storage.service';
import { StorageKey } from '@/app/typings/enums/storage-key.enum';

export function accountState(): IAccountState {
  return {
    me: null,
    token: storage.get(StorageKey.token),
    accountId: storage.get(StorageKey.accountId),
    apiKey: storage.get(StorageKey.apiKey),
    handcashHandle: storage.get(StorageKey.handcashHandle),
    handcashCodeVerified: storage.get(StorageKey.handcashCodeVerified),
    handcashRequestId: storage.get(StorageKey.handcashRequestId),
    handcashAccountCreationId: storage.get(StorageKey.handcashAccountCreationId)
  };
}
