
import { defineComponent } from 'vue';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

export default defineComponent({
  name: 'AccountConnect',
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption
  },
  data() {
    return {
      hasHandcash: null
    };
  },
  methods: {
    submitAnswer() {
      this.$emit('submitted', this.hasHandcash);
    }
  }
});
