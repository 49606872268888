import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "max"]
const _hoisted_2 = ["value", "min"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["date-range", `bg-${_ctx.inputColor}`])
  }, [
    _createElementVNode("input", {
      value: _ctx.modelValue.from,
      max: _ctx.modelValue.to,
      type: "date",
      placeholder: "Date from",
      class: "bg-transparent",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterByDate('from', $event)))
    }, null, 40, _hoisted_1),
    _createElementVNode("input", {
      value: _ctx.modelValue.to,
      min: _ctx.modelValue.from,
      type: "date",
      placeholder: "Date to",
      class: "bg-transparent",
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterByDate('to', $event)))
    }, null, 40, _hoisted_2)
  ], 2))
}