export function getStatusColor(status: 'pending' | 'completed' | string): 'cyan' | 'green' | 'black' {
  switch (status) {
    case 'pending':
      return 'cyan';
    case 'completed':
      return 'green';
    default:
      return 'black';
  }
}
