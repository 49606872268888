
import { defineComponent } from 'vue';
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import Modal from '@/app/components/modals/Modal.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useModal } from '@/app/misc/composables/useModal';
import { IGenerateApiKeyResponse } from '@/app/typings/interfaces/api-responses/generate-api-key-response.interface';

export default defineComponent({
  name: 'RegenerateApiKeyModal',
  components: { Modal, BaseInput },
  setup() {
    const { isOpen, toggleModal } = useModal();
    return { isOpen, toggleModal, v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        password: ''
      },
      apiKey: ''
    };
  },
  validations() {
    return {
      form: {
        password: {
          required
        }
      }
    };
  },
  methods: {
    clearApiKey() {
      this.toggleModal(false);
      this.apiKey = '';
    },
    async onSubmit(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }

      this.$store.dispatch('account/generateApiKey', this.form).then((res: IGenerateApiKeyResponse): void => {
        this.$emit('submitted');
        this.apiKey = res.api_key;
        this.form.password = '';
        //this.toggleModal(false);
      });
    }
  }
});
