import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid gap-4" }
const _hoisted_2 = { class: "pt-4 flex justify-between items-center" }
const _hoisted_3 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "is-open": _ctx.isOpen,
    title: "Change password",
    description: "First type your current password and then set your new password.",
    size: "fit",
    onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleModal(false)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        autocomplete: "off"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_base_input, {
            modelValue: _ctx.form.current_password,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.current_password) = $event)),
            placeholder: "Current password",
            autocomplete: "password",
            type: "password",
            validation: _ctx.v$.form.current_password
          }, null, 8, ["modelValue", "validation"]),
          _createVNode(_component_base_input, {
            modelValue: _ctx.form.new_password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.new_password) = $event)),
            placeholder: "New password",
            autocomplete: "new-password",
            type: "password",
            validation: _ctx.v$.form.new_password
          }, null, 8, ["modelValue", "validation"]),
          _createVNode(_component_base_input, {
            modelValue: _ctx.form.repeat_password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.repeat_password) = $event)),
            placeholder: "Confirm new password",
            autocomplete: "repeat-password",
            type: "password",
            validation: _ctx.v$.form.repeat_password
          }, null, 8, ["modelValue", "validation"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", _hoisted_3, [
            _createTextVNode(" Submit "),
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-right" })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-open"]))
}