import { createStore } from 'vuex';
import { accountModule } from '@/app/store/account/account.module';
import { transactionsModule } from '@/app/store/transactions/transactions.module';
import { paymentRequestsModule } from '@/app/store/payment-requests/payment-requests.module';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { IAppConfig } from '@/app/typings/interfaces/app-config.interface';
import { invoicesModule } from '@/app/store/invoices/invoices.module';

export const store = createStore<IRootState>({
  state: (): IRootState => ({ config: null }),
  mutations: {
    setConfig(state: IRootState, config: IAppConfig) {
      state.config = config;
    }
  },
  modules: {
    account: accountModule,
    transactions: transactionsModule,
    paymentRequests: paymentRequestsModule,
    invoices: invoicesModule
  },
  getters: {
    config: ({ config }: IRootState): IAppConfig => config
  }
});
