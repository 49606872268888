import { Module } from 'vuex';
import { IAccountState } from '@/app/typings/interfaces/store/account-state.interface';
import { accountState } from '@/app/store/account/account.state';
import { accountGetters } from '@/app/store/account/account.getters';
import { accountActions } from '@/app/store/account/account.actions';
import { accountMutations } from '@/app/store/account/account.mutations';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';

export const accountModule: Module<IAccountState, IRootState> = {
  namespaced: true,
  state: accountState,
  getters: accountGetters,
  actions: accountActions,
  mutations: accountMutations
};
