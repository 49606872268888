
import { defineComponent, PropType } from 'vue';
import DataTable from '@/app/components/particles/DataTable.vue';
import { dateFormat } from '@/app/misc/helpers/date-formatter.helper';
import { getColumnsConfig } from '@/app/views/main/invoices/partials/columns.config';
import { IColumnConfig } from '@/app/typings/interfaces/column-config.interface';
import { IInvoiceProduct } from '@/app/typings/interfaces/api-requests-params/create-invoice-request.interface';

export default defineComponent({
  name: 'PreviewTable',
  methods: {
    dateFormat
  },
  components: { DataTable },
  props: {
    total: {
      type: Number,
      default: 0
    },
    items: Array as PropType<IInvoiceProduct[]>
  },
  data() {
    return {
      columns: getColumnsConfig()
        .map((item: IColumnConfig): IColumnConfig => {
          delete item.input;
          item.classes = item.name === 'amount' ? 'border-y text-start' : 'border-y text-center';

          return item;
        })
        .filter((item: IColumnConfig): boolean => item.name !== 'actions')
    };
  }
});
