import { createRouter, createWebHistory, Router } from 'vue-router';

/* Layouts */
import MainLayout from '@/app/components/layouts/main/MainLayout.vue';

/* Views */
import LoginView from '@/app/views/auth/login/LogInView.vue';
import SignUpView from '@/app/views/auth/sign-up/SignUpView.vue';
import FinishSignUpView from '@/app/views/main/finish-sign-up/FinishSignUpView.vue';
import TransactionsView from '@/app/views/main/transactions/TransactionsView.vue';
import PaymentRequestsView from '@/app/views/main/payment-requests/PaymentRequestsView.vue';
import CreateInvoiceView from '@/app/views/main/invoices/CreateInvoiceView.vue';
import TransactionDetailView from '@/app/views/main/transactions/TransactionDetailView.vue';
import AccountView from '@/app/views/main/account/AccountView.vue';
import IntegrationView from '@/app/views/main/integrations/IntegrationView.vue';

/* Resolvers */
import { meResolver } from '@/app/resolvers/me.resolver';
import { transactionDetailsResolver } from '@/app/resolvers/transaction-details.resolver';

/* Guards */
import { authGuard } from '@/app/guards/auth.guard';
import { unAuthGuard } from '@/app/guards/un-auth.guard';

/* Routes */
export const router: Router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/auth',
      name: 'Auth',
      redirect: '/auth/login',
      children: [
        {
          path: 'login',
          name: 'Login',
          component: LoginView
        },
        {
          path: 'sign-up',
          name: 'Sign Up',
          component: SignUpView
        }
      ]
    },
    {
      path: '/',
      name: 'Main',
      component: MainLayout,
      redirect: '/transactions',
      beforeEnter: meResolver,
      children: [
        {
          path: 'transactions',
          name: 'Transactions',
          redirect: '/transactions/list',
          children: [
            {
              path: 'list',
              name: 'Transactions List',
              component: TransactionsView
            },
            {
              path: ':transactionId',
              name: 'Transactions Details',
              component: TransactionDetailView,
              beforeEnter: transactionDetailsResolver
            }
          ]
        },
        {
          path: 'payment-requests',
          name: 'Payment requests',
          component: PaymentRequestsView
        },
        {
          path: 'create-invoice',
          name: 'Create invoice',
          component: CreateInvoiceView
        },
        {
          path: 'account',
          name: 'Account',
          component: AccountView
        },
        {
          path: 'integrations',
          name: 'Integrations',
          component: IntegrationView
        }

      ]
    },
    {
      path: '/finish-sign-up',
      name: 'Complete Sign Up',
      component: FinishSignUpView,
      beforeEnter: meResolver
    }
  ]
});

router.beforeEach(authGuard);
router.beforeEach(unAuthGuard);
