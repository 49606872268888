
import { defineComponent } from 'vue';
import { loader } from '@/app/services/loader.service';
import { TransitionRoot } from '@headlessui/vue';

interface IData {
  isGlobalLoading: boolean;
  isBarLoading: boolean;
}

export default defineComponent({
  name: 'LoaderComponent',
  components: { TransitionRoot },
  props: {
    isGlobal: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data(): IData {
    return {
      isGlobalLoading: false,
      isBarLoading: false
    };
  },
  created(): void {
    loader.isSpinnerEnabled$.subscribe((value: boolean): void => {
      this.isGlobalLoading = value;
    });
    loader.isBarEnabled.subscribe((value: boolean): void => {
      this.isBarLoading = value;
    });
  },
  computed: {
    shouldShowSpinner(): boolean {
      return this.isGlobal ? this.isGlobalLoading : !this.isGlobalLoading && this.isLoading;
    },
    shouldShowBar(): boolean {
      return this.isGlobal && !this.shouldShowSpinner && this.isBarLoading;
    },
    spinnerClasses(): string {
      return [
        'after:border-green',
        'after:border-t-transparent',
        'after:border-r-transparent',
        'before:border-green',
        'before:border-b-transparent',
        'before:border-l-transparent'
      ].join(' ');
    }
  }
});
