import { AbstractApiBaseService } from '@/app/misc/abstract/api-service.abstract';
import { Account } from '@/app/typings/models/account.model';
import { ClassConstructor } from 'class-transformer';
import { delayWhen, Observable, of, switchMap } from 'rxjs';
import { ISMSVerifyRequest } from '@/app/typings/interfaces/api-requests-params/sms-verify-request.interface';
import { ISMSIssueRequest } from '@/app/typings/interfaces/api-requests-params/sms-issue-request.interface';
import { ISMSResponse } from '@/app/typings/interfaces/api-responses/sms-response.interface';
import { IServicesConfig } from '@/app/typings/interfaces/services-config.interface';
import {IUpdateAccountBody} from '@/app/typings/interfaces/api-requests-params/update-account-request.interface';
import {IGenerateApiKeyBody} from '@/app/typings/interfaces/api-requests-params/generate-api-key-request.interface';
import {IGenerateApiKeyResponse} from '@/app/typings/interfaces/api-responses/generate-api-key-response.interface';
import {IEmailVerifyCodeRequest} from '@/app/typings/interfaces/api-requests-params/email-verify-code-request.interface';
import {IEmailVerifyResponse} from '@/app/typings/interfaces/api-responses/email-verify-response.interface';
import {IGenerateEmailVerifyRequest} from '@/app/typings/interfaces/api-requests-params/generate-email-verify-request.interface';
import {IGenerateEmailVerifyResponse} from '@/app/typings/interfaces/api-responses/generate-email-verify-response.interface';
import {ICreateHandcashAccountBody} from '@/app/typings/interfaces/api-requests-params/create-handcash-account-request.interface';
import {ICreateHandcashAccountResponse} from '@/app/typings/interfaces/api-responses/create-handcash-account-response.interface';

export class AccountsApiService extends AbstractApiBaseService<Account> {
  protected readonly _MODEL: ClassConstructor<Account> = Account;
  protected readonly _URL_PATH: string = '/accounts';

  smsVerify(body: ISMSVerifyRequest, servicesConfig?: IServicesConfig): Observable<ISMSResponse> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<ISMSResponse> => this._http.post(`${this.baseUrl}/sms/verify`, body, {}, servicesConfig))
    );
  }
  updateAccount(body: IUpdateAccountBody, servicesConfig?: IServicesConfig): Observable<Account> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<Account> => this._http.put(`${this.baseUrl}/accounts`, body, {}, servicesConfig))
    );
  }

  generateApiKey(body: IGenerateApiKeyBody, servicesConfig?: IServicesConfig): Observable<IGenerateApiKeyResponse> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<IGenerateApiKeyResponse> => this._http.post(`${this.baseUrl}/accounts/apikey`, body, {}, servicesConfig))
    );
  }

  generateEmailVerification(body: IGenerateEmailVerifyRequest, servicesConfig?: IServicesConfig): Observable<IGenerateEmailVerifyResponse> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<IGenerateEmailVerifyResponse> => this._http.post(`${this.baseUrl}/accounts/handcashEmailCode`, body, {}, servicesConfig))
    );
  }

  verifyEmailCode(body: IEmailVerifyCodeRequest, servicesConfig?: IServicesConfig): Observable<IEmailVerifyResponse> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<IEmailVerifyResponse> => this._http.post(`${this.baseUrl}/accounts/handcashVerifyCode`, body, {}, servicesConfig))
    );
  }

  createHandcashAccount(body: ICreateHandcashAccountBody, servicesConfig?: IServicesConfig): Observable<ICreateHandcashAccountResponse> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<ICreateHandcashAccountResponse> => this._http.post(`${this.baseUrl}/accounts/handcashCreateAccount`, body, {}, servicesConfig))
    );
  }

  smsIssue(body: ISMSIssueRequest, servicesConfig?: IServicesConfig): Observable<ISMSResponse> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<ISMSResponse> => this._http.post(`${this.baseUrl}/sms/issue`, body, null, servicesConfig))
    );
  }
}

export const accountsApi: AccountsApiService = new AccountsApiService();
