import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center gap-1 text-sm mb-1"
}
const _hoisted_3 = {
  key: 0,
  class: "text-red text-lg leading-none"
}
const _hoisted_4 = { class: "flex relative" }
const _hoisted_5 = ["rows", "readonly", "placeholder", "autocomplete"]
const _hoisted_6 = {
  key: 1,
  class: "text-gray-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.isRequired)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, [
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          rows: _ctx.rows,
          class: _normalizeClass(["w-full p-4 min-h-[100px]", {
            [`bg-${_ctx.inputColor}`]: true,
            'border-red': this.errorObjects?.length
          }]),
          readonly: _ctx.readonly,
          placeholder: _ctx.placeholder,
          autocomplete: _ctx.autocomplete,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', $event))),
          onChange: _cache[2] || (_cache[2] = ($event: any) => {
            _ctx.$emit('change', $event);
            _ctx.validation?.$touch?.();
          }),
          onBlur: _cache[3] || (_cache[3] = ($event: any) => {
            _ctx.$emit('blur', $event);
            _ctx.validation?.$touch?.();
          }),
          onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('focus', $event)))
        }, null, 42, _hoisted_5), [
          [_vModelText, _ctx.model]
        ])
      ]),
      (_ctx.shouldShowHint)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.hint), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorObjects, (error) => {
        return (_openBlock(), _createElementBlock("li", {
          key: error?.$uid,
          class: "text-red text-sm mt-1"
        }, _toDisplayString(_ctx.getErrorMessage(error)), 1))
      }), 128))
    ])
  ]))
}