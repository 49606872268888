import { AbstractModel } from '@/app/typings/models/_base.model';
import { Exclude, Expose, Transform, TransformFnParams } from 'class-transformer';

@Exclude()
export class Transaction extends AbstractModel {
  @Expose() id: string;
  @Expose({ name: 'account_id' }) accountId: string;
  @Expose() currency: string;
  @Expose({ name: 'customer_address' }) customerAddress: string;
  @Expose({ name: 'customer_email' }) customerEmail: string;
  @Expose({ name: 'customer_name' }) customerName: string;
  @Expose({ name: 'customer_phone' }) customerPhone: string;
  @Expose({ name: 'fee_usd' }) feeUsd: number;
  @Expose({ name: 'handcash_id' }) handcashId: number;
  @Expose({ name: 'order_id' }) orderId: string;
  @Expose({ name: 'sale_amount_usd' }) saleAmountUsd: number;

  @Expose()
  @Transform(({ obj }: TransformFnParams): number => obj.sale_amount_usd - obj.fee_usd)
  netSale: number;
}
