
import { defineComponent } from 'vue';

import BaseInput from '@/app/components/particles/forms/BaseInput.vue';

export default defineComponent({
  name: 'AccountHandcash',
  components: {
    BaseInput
  },
  props: {
    hasHandcash: Boolean,
    email: String,
    isUsedInModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        username: '',
        email: '',
        handcash_handle: ''
      },
      step: 0,
      verify: {
        email: '',
        code: '',
        requestId: ''
      }
    };
  },
  methods: {
    backToConnect() {
      this.$emit('back-to-connect');
    },
    async updateAccountWithHandle(): Promise<void> {
      this.$store.dispatch('account/update', this.form).then((): void => {
        this.$emit('submitted');
        if (!this.isUsedInModal) {
          this.$router.push('/');
        }
      });
    },
    async generateEmailVerification(): Promise<void> {
      this.$store.dispatch('account/generateEmailVerification', this.verify).then((): void => {
        this.$emit('submitted');
        this.step = 1;
      });
    },
    async verifyEmailCode(): Promise<void> {
      this.$store.dispatch('account/verifyEmailCode', this.verify).then((): void => {
        this.$emit('submitted');
        this.$store.dispatch('account/createHandcashAccount', this.verify).then((): void => {
          this.$emit('submitted');
          this.$router.push('/');
        });
      });
    }
  }
});
