import { MutationTree } from 'vuex';
import { IPaymentRequestsState } from '@/app/typings/interfaces/store/payment-requests-state.interface';
import { PaymentRequest } from '@/app/typings/models/payment-request.model';
import { paymentRequestsState } from '@/app/store/payment-requests/payment-requests.state';

export const paymentRequestsMutations: MutationTree<IPaymentRequestsState> = {
  setItems(state: IPaymentRequestsState, items: PaymentRequest[]): void {
    state.items = items;
  },

  setLoadingState(state: IPaymentRequestsState, isLoading: boolean): void {
    state.isLoading = isLoading;
  },

  cleanState(state: IPaymentRequestsState): void {
    Object.entries(paymentRequestsState()).forEach(([key, value]: [string, any]): void => {
      state[key as keyof IPaymentRequestsState] = value;
    });
  }
};
