
import { mapGetters } from 'vuex';
import BaseTextarea from '@/app/components/particles/forms/BaseTextarea.vue';
import Modal from '@/app/components/modals/Modal.vue';
import { defineComponent, PropType } from 'vue';
import { useModal } from '@/app/misc/composables/useModal';
import { ICreateInvoiceFormData } from '@/app/views/main/invoices/CreateInvoiceView.vue';
import { Account } from '@/app/typings/models/account.model';

export default defineComponent({
  name: 'InvoicePreviewModal',
  components: {
    BaseTextarea,
    Modal
  },
  setup() {
    return useModal();
  },
  async mounted(): Promise<void> {
    const { image }: Account = this.$store.getters['account/me'];

    if (image) {
      await image?.getFile();
    }
  },
  data() {
    return {
      form: {
        body: ''
      },
      options: [
        { name: 'Devvly, LLC', email: 'greg@devvly.com' },
        { name: 'First Recipient Name', email: 'example+1@email.com' },
        { name: 'Second Recipient Name', email: 'example+2@email.com' },
        { name: 'Third Recipient Name', email: 'example+3@email.com' }
      ]
    };
  },
  props: {
    invoiceData: {
      type: Object as PropType<ICreateInvoiceFormData>
    }
  },
  computed: {
    ...mapGetters({
      me: 'account/me'
    })
  }
});
