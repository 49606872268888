import { Exclude, Expose, Transform, TransformFnParams } from 'class-transformer';
import { AbstractModel } from '@/app/typings/models/_base.model';

function getNameFromUrl(url: string): string {
  const [origin]: string[] = url?.split?.('?') ?? [];
  const [name]: string[] = origin?.split('/')?.reverse?.() ?? [];
  return name;
}

@Exclude()
export class Image extends AbstractModel {
  @Expose({ name: 'key' }) id: string;
  @Expose()
  url: string;
  @Expose()
  @Transform(({ obj }: TransformFnParams): string => getNameFromUrl(obj.url))
  name: string;
  @Expose() file: File;

  async getFile(): Promise<File> {
    if (this.file) {
      return this.file;
    } else {
      const response: Response = await fetch(this.url);
      const blob: Blob = await response.blob();

      this.file = new File([blob], getNameFromUrl(this.url));
      return this.file;
    }
  }
}
