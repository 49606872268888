import { from, Observable } from 'rxjs';
import { Account } from '@/app/typings/models/account.model';
import { http, HttpService } from '@/app/services/http.service';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { router } from '@/app/app-routing';
import { ILoginParams } from '@/app/typings/interfaces/params/login-params.interface';
import { store } from '@/app/store/store';
import { IErrorResponse } from '@/app/typings/interfaces/api-responses/error-response.interface';

export class AuthService {
  private _http: HttpService = http;

  get token(): string {
    return store.getters['account/token'];
  }

  constructor() {
    this._http.client.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
      return this._addTokenToRequest(config);
    });

    this._http.client.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError<IErrorResponse>): Promise<never> => {
        const isUnauthorizedResponse: boolean = error.response?.status === 401;
        const isNotIgnoredEndpoint: boolean = ['/login'].every((endpoint: string): boolean => !error.config.url.includes(endpoint));
        const isNotIgnoredRoute: boolean = ['/auth/'].every(
          (routePart: string): boolean => !router?.currentRoute?.value?.fullPath?.includes?.(routePart)
        );

        if (isUnauthorizedResponse && isNotIgnoredEndpoint && isNotIgnoredRoute) {
          router.push('/auth/login');
        }
        return Promise.reject(error);
      }
    );
  }

  login(body: ILoginParams): Observable<Account> {
    return from(store.dispatch('account/login', body));
  }

  logout(): Observable<void> {
    store.commit('transactions/cleanState');
    store.commit('paymentRequests/cleanState');
    return from(store.dispatch('account/logout'));
  }

  private _addTokenToRequest<T>(config: AxiosRequestConfig<T>): AxiosRequestConfig<T> {
    if (this.token) {
      config.headers = {
        Authorization: `Bearer ${this.token}`
      };
    }

    return config;
  }
}

export const auth: AuthService = new AuthService();
