import { Module } from 'vuex';
import { IInvoicesState } from '@/app/typings/interfaces/store/invoices-state.interface';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { invoicesActions } from '@/app/store/invoices/invoices.actions';
import { invoicesMutations } from '@/app/store/invoices/invoices.mutations';
import { invoicesState } from '@/app/store/invoices/invoices.state';
import { invoicesGetters } from '@/app/store/invoices/invoices.getters';

export const invoicesModule: Module<IInvoicesState, IRootState> = {
  namespaced: true,
  state: invoicesState,
  actions: invoicesActions,
  mutations: invoicesMutations,
  getters: invoicesGetters
};
