
import { defineComponent, PropType } from 'vue';
import { ErrorObject, Validation } from '@vuelidate/core';
import get from 'lodash.get';

export default defineComponent({
  name: 'BaseTextarea',
  props: {
    modelValue: [String, Number],
    value: [String, Number],
    placeholder: String,
    readonly: Boolean,
    autocomplete: String,
    label: String,
    hint: String,
    rows: {
      type: Number,
      default: 8
    },
    validation: Object as PropType<Validation>,
    validationErrorsPath: {
      type: String,
      default: '$errors'
    },
    customValidationMessages: Object as PropType<{ [key: string]: string }>,
    inputColor: {
      type: String as PropType<'gray-100' | 'white'>,
      default: 'gray-100'
    }
  },
  emits: {
    ['update:modelValue']: (value: string | number): boolean => ['string', 'number'].includes(typeof value),
    input: (value: InputEvent): boolean => Boolean(value),
    change: (value: Event): boolean => Boolean(value),
    focus: (value: FocusEvent): boolean => Boolean(value),
    blur: (value: FocusEvent): boolean => Boolean(value)
  },
  computed: {
    shouldShowHint(): boolean {
      return this.hint && !this.errorObjects?.length;
    },
    isRequired(): boolean {
      return Boolean(this.validation && 'required' in this.validation);
    },
    errorObjects(): ErrorObject[] {
      return get(this.validation, this.validationErrorsPath);
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    getErrorMessage({ $message, $validator }: ErrorObject) {
      switch ($validator) {
        default:
          return this.customValidationMessages?.[$validator] ?? $message;
      }
    }
  }
});
