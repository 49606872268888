import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a97c7186"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-checkbox" }
const _hoisted_2 = { class: "base-checkbox__element" }
const _hoisted_3 = {
  key: 0,
  class: "ml-0.5 text-red text-lg leading-none"
}
const _hoisted_4 = {
  key: 0,
  class: "base-checkbox__errors text-red mt-1 flex flex-col gap-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
      }, null, 512), [
        [_vModelCheckbox, _ctx.model]
      ]),
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        (_ctx.isRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.shouldShowError)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorObjects, (error) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `${error?.$property}-${error?.$validator}`,
              class: "text-sm"
            }, _toDisplayString(_ctx.getErrorMessage(error)), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}