import { GetterTree } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { IPaymentRequestsState } from '@/app/typings/interfaces/store/payment-requests-state.interface';
import { PaymentRequest } from '@/app/typings/models/payment-request.model';
import { groupItemsByDate } from '@/app/misc/helpers/group-items-by-date.helper';
import { IDateGroup } from '@/app/typings/interfaces/date-map.interface';

export const paymentRequestsGetters: GetterTree<IPaymentRequestsState, IRootState> = {
  isLoading({ isLoading }: IPaymentRequestsState): boolean {
    return isLoading;
  },

  items({ items }: IPaymentRequestsState): PaymentRequest[] {
    return items;
  },

  dateGroupedItems({ items }: IPaymentRequestsState): IDateGroup<PaymentRequest>[] {
    return groupItemsByDate<PaymentRequest>(items, 'desc');
  }
};
