import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center gap-1 text-sm mb-1"
}
const _hoisted_3 = {
  key: 0,
  class: "text-red text-lg leading-none"
}
const _hoisted_4 = { class: "flex relative" }
const _hoisted_5 = {
  key: 0,
  class: "absolute top-1/2 -translate-y-1/2 left-4 z-10"
}
const _hoisted_6 = ["value", "type", "max", "min", "step", "readonly", "placeholder", "autocomplete"]
const _hoisted_7 = {
  key: 1,
  class: "text-gray-500 text-sm mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.floatingErrors ? 'floating-errors' : '', "relative"])
  }, [
    _createElementVNode("label", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.isRequired)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, [
        (_ctx.prefix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.prefix), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          value: _ctx.value ?? _ctx.model,
          type: _ctx.type,
          max: _ctx.max,
          min: _ctx.min,
          step: _ctx.step,
          readonly: _ctx.readonly,
          placeholder: _ctx.placeholder,
          autocomplete: _ctx.autocomplete,
          class: _normalizeClass({
            '!pl-8': _ctx.prefix,
            [`bg-${_ctx.inputColor}`]: true,
            'border-red': _ctx.shouldShowError
          }),
          onAccept: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onAccept && _ctx.onAccept(...args))),
          onComplete: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onComplete && _ctx.onComplete(...args))),
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('input', $event))),
          onChange: _cache[4] || (_cache[4] = ($event: any) => {
            _ctx.$emit('change', $event);
            _ctx.validation?.$touch?.();
          }),
          onBlur: _cache[5] || (_cache[5] = ($event: any) => {
            _ctx.$emit('blur', $event);
            _ctx.validation?.$touch?.();
            _ctx.isFocused = false;
          }),
          onFocus: _cache[6] || (_cache[6] = ($event: any) => {
            _ctx.$emit('focus', $event);
            _ctx.isFocused = true;
          })
        }, null, 42, _hoisted_6), [
          [_directive_mask, _ctx.mask],
          [_vModelDynamic, _ctx.model]
        ])
      ]),
      (_ctx.shouldShowHint)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.hint), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.shouldShowError)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: _normalizeClass([
        _ctx.floatingErrors
          ? 'bg-red text-white rounded-lg p-3 absolute top-14 left-0 right-0 z-50 pointer-events-none '
          : 'text-red mt-1 flex flex-col gap-1',
        _ctx.floatingErrors && !_ctx.isFocused ? 'opacity-0' : ''
      ])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorObjects, (error) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `${error?.$property}-${error?.$validator}`,
              class: "text-sm"
            }, _toDisplayString(_ctx.getErrorMessage(error)), 1))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}