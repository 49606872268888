
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { getCryptoIconPath } from '@/app/misc/helpers/getters/get-crypto-icon-path.helper';
import { getStatusColor } from '@/app/misc/helpers/getters/get-status-color.helper';
import { dateFormat } from '@/app/misc/helpers/date-formatter.helper';
import { numberFormat } from '@/app/misc/helpers/number-formatter.helper';

export default defineComponent({
  name: 'DetailView',
  data() {
    return {
      currentDollarValue: null,
      dollarEquivalent: null
    };
  },
  computed: {
    ...mapGetters({
      transactions: 'transactions/items',
      current: 'transactions/current'
    }),

    currencyIcon(): string {
      return getCryptoIconPath(this.current.currency);
    },

    statusColor(): string {
      return getStatusColor(this.current.status);
    },

    total(): number {
      return ((this.currentDollarValue ?? 0 - this.dollarEquivalent ?? 0) * 100) / this.dollarEquivalent ?? 0;
    },

    totalColor(): 'red' | 'green' | 'black' {
      if (this.currentDollarValue > this.dollarEquivalent) {
        return 'green';
      }

      if (this.currentDollarValue < this.dollarEquivalent) {
        return 'red';
      }

      return 'black';
    },

    arrowDirection(): 'up' | 'down' | null {
      switch (this.totalColor) {
        case 'red':
          return 'down';
        case 'green':
          return 'up';
        case 'black':
        default:
          return null;
      }
    }
  },
  methods: {
    dateFormat,
    numberFormat
  }
});
