import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { store } from '@/app/store/store';

export async function meResolver(
  { meta }: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  meta['me'] = store.getters['account/me'] ?? (await store.dispatch('account/getMe'));
  next();
}
