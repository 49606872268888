
import { defineComponent } from 'vue';
import TransactionItem from '@/app/components/particles/TransactionItem.vue';
import FiltersComponent from '@/app/components/particles/Filters.vue';
import LoaderComponent from '@/app/components/particles/Loader.vue';
import FilteredSubtotal from '@/app/components/particles/FilteredSubtotal.vue';
import { dateFormat } from '@/app/misc/helpers/date-formatter.helper';
import { mapGetters } from 'vuex';
import { IRange } from '@/app/typings/interfaces/range.interface';
import { Transaction } from '@/app/typings/models/transaction.model';
import { IDateGroup } from '@/app/typings/interfaces/date-map.interface';
import { dateGroupByDateRangeFilter } from '@/app/misc/helpers/filters/date-group-by-date-range.filter';
import { getDateGroupsTotalAmount } from '@/app/misc/helpers/getters/get-date-groups-total-amount.helper';
import { numberFormat } from '@/app/misc/helpers/number-formatter.helper';

interface IData {
  range: IRange;
}

export default defineComponent({
  name: 'TransactionsView',
  components: {
    FilteredSubtotal,
    LoaderComponent,
    FiltersComponent,
    TransactionItem
  },
  data(): IData {
    return {
      range: {
        from: null,
        to: null
      }
    };
  },
  computed: {
    ...mapGetters({
      dateGroupedItems: 'transactions/dateGroupedItems',
      isLoading: 'transactions/isLoading'
    }),
    filteredItems(): IDateGroup<Transaction>[] {
      return this.dateGroupedItems.filter(dateGroupByDateRangeFilter(this.range));
    },
    totalCryptoCoins(): string {
      return numberFormat(getDateGroupsTotalAmount(this.filteredItems, 'saleAmountUsd'));
    },
    totalFiatMoney(): string {
      return numberFormat(getDateGroupsTotalAmount(this.filteredItems, 'saleAmountUsd'));
    }
  },
  async created() {
    await this.$store.dispatch('transactions/getItems', { skipSpinnerStart: true });
  },
  methods: {
    dateFormat,
    onDateFilter(range: IRange) {
      this.range = range;
    }
  }
});
