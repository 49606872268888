import { AbstractApiBaseService } from '@/app/misc/abstract/api-service.abstract';
import { Transaction } from '@/app/typings/models/transaction.model';
import { ClassConstructor } from 'class-transformer';

export class TransactionsApiService extends AbstractApiBaseService<Transaction> {
  protected readonly _MODEL: ClassConstructor<Transaction> = Transaction;
  protected readonly _URL_PATH: string = '/transactions';
}

export const transactionsApi: TransactionsApiService = new TransactionsApiService();
