
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ToastContent',
  props: {
    title: String,
    message: String
  }
});
