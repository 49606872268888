
import { defineComponent, PropType } from 'vue';
import { ErrorObject, Validation } from '@vuelidate/core';
import { AnyMaskedOptions } from 'imask';
const { IMaskDirective } = require('vue-imask');
import { PHONE_MASK_OPTIONS } from '@/app/misc/constants';
import get from 'lodash.get';

interface IData {
  unmaskedValue: string;
  isFocused: boolean;
}
export type inputType = 'text' | 'number' | 'email' | 'password' | 'search' | 'tel' | 'url' | 'date';
export default defineComponent({
  name: 'BaseInput',
  directives: { mask: IMaskDirective },
  props: {
    min: Number,
    max: Number,
    step: Number,
    modelValue: [String, Number],
    value: [String, Number],
    placeholder: String,
    readonly: Boolean,
    autocomplete: String,
    label: String,
    hint: String,
    prefix: String,
    floatingErrors: Boolean,
    validation: Object as PropType<Validation>,
    validationErrorsPath: {
      type: String,
      default: '$errors'
    },
    customValidationMessages: Object as PropType<{ [key: string]: string }>,
    type: {
      type: String as PropType<inputType>,
      default: 'text'
    },
    hideRequiredMark: {
      type: Boolean,
      default: false
    },
    inputColor: {
      type: String as PropType<'gray-100' | 'white'>,
      default: 'gray-100'
    }
  },
  data(): IData {
    return {
      unmaskedValue: '',
      isFocused: false
    };
  },
  emits: {
    ['update:modelValue']: (value: string | number): boolean => ['string', 'number'].includes(typeof value),
    input: (value: InputEvent): boolean => Boolean(value),
    change: (value: Event): boolean => Boolean(value),
    focus: (value: FocusEvent): boolean => Boolean(value),
    blur: (value: FocusEvent): boolean => Boolean(value)
  },
  computed: {
    mask(): AnyMaskedOptions | null {
      switch (this.type) {
        case 'tel':
          return PHONE_MASK_OPTIONS;
        default:
          return null;
      }
    },
    shouldShowHint(): boolean {
      return this.hint && !this.errorObjects?.length;
    },
    shouldShowError(): boolean {
      return Boolean(this.validation?.$invalid && this.validation?.$dirty && this.errorObjects?.length);
    },
    isRequired(): boolean {
      return !this.hideRequiredMark && Boolean(this.validation && 'required' in this.validation);
    },
    errorObjects(): ErrorObject[] {
      return get(this.validation, this.validationErrorsPath);
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', !this.mask ? value : this.unmaskedValue);
      }
    }
  },
  methods: {
    onAccept({ detail }: CustomEvent): void {
      this.unmaskedValue = detail.unmaskedValue;
      this.model = detail.unmaskedValue;
    },
    onComplete({ detail }: CustomEvent): void {
      this.unmaskedValue = detail.unmaskedValue;
      this.model = detail.unmaskedValue;
    },
    getErrorMessage({ $message, $validator }: ErrorObject) {
      switch ($validator) {
        case 'phone':
          return `The "${this.placeholder}" field must be a valid phone number`;
        case 'email':
          return `The "${this.placeholder}" field must be a valid email`;
        default:
          return this.customValidationMessages?.[$validator] ?? $message;
      }
    }
  }
});
