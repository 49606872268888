import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box p-6" }
const _hoisted_2 = {
  target: "_",
  href: "https://app.2agateway.com/2a-gateway-wp-woo-plugin.zip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioGroupLabel = _resolveComponent("RadioGroupLabel")!
  const _component_RadioGroupOption = _resolveComponent("RadioGroupOption")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RadioGroup, null, {
      default: _withCtx(() => [
        _createVNode(_component_RadioGroupLabel, { class: "md:text-lg font-bold mb-2 block" }, {
          default: _withCtx(() => [
            _createTextVNode("Click your desired plugin for a download link")
          ]),
          _: 1
        }),
        _createVNode(_component_RadioGroupOption, {
          value: true,
          class: "inline-block font-bold mr-4"
        }, {
          default: _withCtx(({ checked }) => [
            _createElementVNode("a", _hoisted_2, [
              _createElementVNode("span", {
                class: _normalizeClass(["radio-group-option", checked ? 'border-2 border-green-500' : 'bg-white border m-px border-gray-300'])
              }, " WooCommerce ", 2)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}