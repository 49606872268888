
import { defineComponent } from 'vue';
import { auth } from '@/app/services/auth.service';
import { Account } from '@/app/typings/models/account.model';
import { mapGetters } from 'vuex';
import { email, required } from '@vuelidate/validators';
import { from, mergeMap, Observable, of } from 'rxjs';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

export default defineComponent({
  name: 'IntegrationView',
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption
  },
  data() {
    const { email, companyName, phone, image }: Account = this.$store.getters['account/me'];

    return {
      form: {
      },
      files: image?.file ? [image.file] : []
    };
  },
  computed: {
    ...mapGetters({
      me: 'account/me'
    })
  },
  methods: {
  }
});
