import { from, map, Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IServicesConfig } from '@/app/typings/interfaces/services-config.interface';
import { loader, LoaderService } from '@/app/services/loader.service';
import { ToastInterface, useToast } from 'vue-toastification';
import { IErrorResponse } from '@/app/typings/interfaces/api-responses/error-response.interface';
import ToastContent from '@/app/components/particles/ToastContent.vue';

export class HttpService {
  client: AxiosInstance = axios.create();
  private _notification: ToastInterface = useToast();
  private _loader: LoaderService = loader;

  get<T = any>(url: string, config: AxiosRequestConfig, services?: IServicesConfig | null): Observable<T> {
    this._startLoader(services);

    return from(this.client.get<T>(url, config)).pipe(
      map(({ data }: AxiosResponse<T>): T => data),
      tap(this._onSuccess.bind(this, services)),
      catchError(this._onError.bind(this, services)),
      finalize(this._onEveryCase.bind(this, services))
    );
  }

  post<T = any>(url: string, body: any | null, config?: AxiosRequestConfig, services?: IServicesConfig | null): Observable<T> {
    this._startLoader(services);

    return from(this.client.post<T>(url, body, config)).pipe(
      map(({ data }: AxiosResponse<T>): T => data),
      tap(this._onSuccess.bind(this, services)),
      catchError(this._onError.bind(this, services)),
      finalize(this._onEveryCase.bind(this, services))
    );
  }

  patch<T = any>(url: string, body: any | null, config?: AxiosRequestConfig, services?: IServicesConfig | null): Observable<T> {
    this._startLoader(services);

    return from(this.client.patch<T>(url, body, config)).pipe(
      map(({ data }: AxiosResponse<T>): T => data),
      tap(this._onSuccess.bind(this, services)),
      catchError(this._onError.bind(this, services)),
      finalize(this._onEveryCase.bind(this, services))
    );
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig, services?: IServicesConfig | null): Observable<T> {
    this._startLoader(services);

    return from(this.client.delete<T>(url, config)).pipe(
      map(({ data }: AxiosResponse<T>): T => data),
      tap(this._onSuccess.bind(this, services)),
      catchError(this._onError.bind(this, services)),
      finalize(this._onEveryCase.bind(this, services))
    );
  }

  put<T = any>(url: string, body: any | null, options?: AxiosRequestConfig, services?: IServicesConfig | null): Observable<T> {
    this._startLoader(services);

    return from(this.client.put<T>(url, body, options)).pipe(
      map(({ data }: AxiosResponse<T>): T => data),
      tap(this._onSuccess.bind(this, services)),
      catchError(this._onError.bind(this, services)),
      finalize(this._onEveryCase.bind(this, services))
    );
  }

  private _onSuccess(config: IServicesConfig): void {
    if (config?.showSuccessNotification) {
      this._notification.success({
        component: ToastContent,
        props: {
          title: 'Success!',
          message: config?.showSuccessNotification?.text ?? 'Request successfully sent!'
        }
      });
    }
  }

  private _onError(config: IServicesConfig, error: AxiosError<IErrorResponse>): Observable<never> {
    if (
      !config ||
      !(typeof config.skipErrorNotification === 'boolean' ? config.skipErrorNotification : config.skipErrorNotification?.(error))
    ) {
      this._notification.error({
        component: ToastContent,
        props: {
          title: error.message,
          message: error.response?.data?.message
        }
      });
    }

    throw error;
  }

  private _onEveryCase(config: IServicesConfig): void {
    this._endLoader(config);
  }

  private _startLoader(config: IServicesConfig): void {
    this._loader.on(!config || (config && !config.skipSpinnerStart));
  }

  private _endLoader(config: IServicesConfig): void {
    if (!config || (config && !config.skipLoaderEnd)) {
      this._loader.off();
    }
  }
}

export const http: HttpService = new HttpService();
