import { Ref, ref } from 'vue';

export function useModal(): { isOpen: Ref<boolean>; toggleModal: (isOpen: boolean) => void } {
  const isOpen: Ref<boolean> = ref(false);
  function toggleModal(isOpened?: boolean): void {
    isOpen.value = typeof isOpened === 'boolean' ? isOpened : !isOpen.value;
  }

  return { toggleModal, isOpen };
}
