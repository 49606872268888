import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center px-4 pb-5" }
const _hoisted_2 = { class: "flex items-center group" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSteps, (step, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        index
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["w-full md:w-20 h-0.5 bg-gray-300 group-first:hidden", {
          'bg-gray-700': _ctx.steps[index - 1]?.isCompleted
        }])
            }, null, 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", {
            class: _normalizeClass(["relative rounded-full h-9 w-9 flex items-center justify-center bg-transparent border-2 font-bold", {
            'border-green text-green-700': step.isActive,
            'border-gray-300 bg-gray-300 text-gray-800': !step.isActive,
            'border-gray-700 text-white bg-gray-700': step.isCompleted
          }])
          }, [
            (!step.isCompleted)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(index + 1), 1))
              : _createCommentVNode("", true),
            (step.isCompleted)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 1,
                  icon: "fa-solid fa-check"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              class: _normalizeClass(["absolute top-[calc(100%+4px)] text-sm text-center w-28 font-100", { 'text-gray-700': step.isCompleted }])
            }, _toDisplayString(step.title.toUpperCase()), 3)
          ], 2)
        ])
      ], 64))
    }), 128))
  ]))
}