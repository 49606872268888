import { GetterTree } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { ITransactionsState } from '@/app/typings/interfaces/store/transactions-state.interface';
import { Transaction } from '@/app/typings/models/transaction.model';
import { groupItemsByDate } from '@/app/misc/helpers/group-items-by-date.helper';
import { IDateGroup } from '@/app/typings/interfaces/date-map.interface';

export const transactionsGetters: GetterTree<ITransactionsState, IRootState> = {
  isLoading({ isLoading }: ITransactionsState): boolean {
    return isLoading;
  },

  items({ items }: ITransactionsState): Transaction[] {
    return items;
  },

  dateGroupedItems({ items }: ITransactionsState): IDateGroup<Transaction>[] {
    return groupItemsByDate<Transaction>(items, 'desc');
  },

  current({ current }: ITransactionsState): Transaction {
    return current;
  }
};
