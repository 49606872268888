
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import {Account} from '@/app/typings/models/account.model';

export default defineComponent({
  name: 'MainLayout',
  async mounted(): Promise<void> {
    const { handcashHandle }: Account = this.$store.getters['account/me'];
    console.log(handcashHandle);
    if (handcashHandle == '') {
      this.$router.push('/finish-sign-up');
    }
  },
  computed: {
    ...mapGetters({
      me: 'account/me'
    })
  }
});
