import { store } from '@/app/store/store';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { auth } from '@/app/services/auth.service';
import { firstValueFrom } from 'rxjs';

export async function unAuthGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> {
  if (to.fullPath.includes('/auth') && store.getters['account/isAuthenticated']) {
    await firstValueFrom(auth.logout());
  }

  next();
}
