
import { defineComponent } from 'vue';
import ModalComponent from '@/app/components/modals/Modal.vue';
import SignUpView from '@/app/views/auth/sign-up/SignUpView.vue';
import { useModal } from '@/app/misc/composables/useModal';

export default defineComponent({
  name: 'ConnectHandCashModal',
  components: {
    SignUpView,
    Modal: ModalComponent
  },
  setup() {
    return useModal();
  }
});
