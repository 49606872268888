import { StorageKey } from '@/app/typings/enums/storage-key.enum';
import { BehaviorSubject } from 'rxjs';

export class StorageService {
  private _shouldUseLocalstorage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  set shouldUseLocalstorage(value: boolean) {
    this._shouldUseLocalstorage$.next(value);
  }

  private get _current(): Storage {
    const alreadyUsedStorage: Storage = [sessionStorage, localStorage].find((storage: Storage): string =>
      storage.getItem(StorageKey.token)
    );
    const newStorage: Storage = this._shouldUseLocalstorage$.value ? localStorage : sessionStorage;

    return alreadyUsedStorage || newStorage;
  }

  get<T>(key: string): T {
    const currentStorage: Storage = [sessionStorage, localStorage].find((storage: Storage): boolean => Boolean(storage.getItem(key)));

    try {
      return currentStorage?.getItem(key) ? JSON.parse(atob(currentStorage?.getItem(key).split('').reverse().join(''))) : null;
    } catch (err) {
      console.warn(`Can't decode value: `, err);
      return null;
    }
  }

  set(name: StorageKey | string, value: any): void {
    this._current.setItem(
      name,
      btoa(JSON.stringify(value ?? ''))
        .replace(/=/gi, '')
        .split('')
        .reverse()
        .join('')
    );
  }

  remove(key: string): void {
    [sessionStorage, localStorage].forEach((storage: Storage): void => {
      storage.removeItem(key);
    });
  }

  clear(): void {
    [sessionStorage, localStorage].forEach((storage: Storage): void => storage.clear());
  }
}

export const storage: StorageService = new StorageService();
