import { MutationTree } from 'vuex';
import { IAccountState } from '@/app/typings/interfaces/store/account-state.interface';
import { Account } from '@/app/typings/models/account.model';
import { storage } from '@/app/services/storage.service';
import { StorageKey } from '@/app/typings/enums/storage-key.enum';

export const accountMutations: MutationTree<IAccountState> = {
  setMe(state: IAccountState, me: Account): void {
    state.me = me;
  },

  setToken(state: IAccountState, token: string): void {
    storage.set(StorageKey.token, token);
    state.token = token;
  },

  setApiKey(state: IAccountState, apiKey: string): void {
    storage.set(StorageKey.apiKey, apiKey);
    state.apiKey = apiKey;
  },

  setEmailId(state: IAccountState, id: string): void {
    storage.set(StorageKey.emailRequestId, id);
    state.accountId = id;
  },

  setAccountId(state: IAccountState, id: string): void {
    storage.set(StorageKey.accountId, id);
    state.accountId = id;
  },

  clear(state: IAccountState): void {
    state.me = null;
    state.token = null;
    state.accountId = null;
    storage.clear();
  }
};
