import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center gap-1 text-sm mb-1"
}
const _hoisted_3 = {
  key: 0,
  class: "text-red text-lg leading-none"
}
const _hoisted_4 = { class: "font-bold pointer-events-none" }
const _hoisted_5 = { class: "pointer-events-none" }
const _hoisted_6 = ["multiple", "accept"]
const _hoisted_7 = {
  key: 0,
  class: "border-t mt-3 pt-3 text-sm pointer-events-none flex align-middle justify-center gap-2"
}
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "w-[100px] text-ellipsis overflow-hidden text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_loader_component = _resolveComponent("loader-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_loader_component, {
      class: "!block relative",
      "is-loading": _ctx.isLoading,
      "is-global": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["file-upload cursor-pointer", {
          'border-green bg-white': _ctx.isDragActive,
          'opacity-50': _ctx.isLoading
        }]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleUpload && _ctx.toggleUpload(...args))),
          onDragover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDragover && _ctx.onDragover(...args))),
          onDragleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onDragleave && _ctx.onDragleave(...args))),
          onDrop: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "fa-solid fa-upload",
            class: "mb-2 text-gray-400 text-2xl pointer-events-none"
          }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.description), 1),
          _createElementVNode("input", {
            ref: "input",
            multiple: _ctx.multiple,
            accept: _ctx.accept,
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            class: "absolute top-0 left-0 h-0 w-0 pointer-events-none opacity-0"
          }, null, 40, _hoisted_6),
          (_ctx.model.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (file, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "leading-4 flex flex-wrap flex-col items-center justify-center"
                  }, [
                    _createElementVNode("img", {
                      class: "h-[100px] w-[100px] object-cover rounded-lg border bg-gray-100",
                      src: _ctx.getFileUrl(file),
                      alt: file.name
                    }, null, 8, _hoisted_8),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(file.name), 1)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 34)
      ]),
      _: 1
    }, 8, ["is-loading"])
  ]))
}