export enum StorageKey {
  token = 'token',
  accountId = 'accountId',
  apiKey = 'apiKey',
  emailRequestId = 'emailRequestId',
  handcashHandle = 'handcashHandle',
  handcashRequestId = 'handcashRequestId',
  handcashCodeVerified = 'handcashCodeVerified',
  phoneVerified = 'phoneVerified',
  handcashAccountCreationId = 'handcashAccountCreationId'
}
