import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { IPaymentRequestsState } from '@/app/typings/interfaces/store/payment-requests-state.interface';
import { runInAnyCase } from '@/app/misc/helpers/rxjs-operators/run-in-any-case.operator';
import { PaymentRequest } from '@/app/typings/models/payment-request.model';
import { firstValueFrom } from 'rxjs';
import { IServicesConfig } from '@/app/typings/interfaces/services-config.interface';
import { paymentRequestsApi } from '@/app/services/api/payment-requests-api.service';
import { tap } from 'rxjs/operators';

export const paymentRequestsActions: ActionTree<IPaymentRequestsState, IRootState> = {
  getItems({ commit }: ActionContext<IPaymentRequestsState, IRootState>, servicesConfig: IServicesConfig): Promise<PaymentRequest[]> {
    commit('setLoadingState', true);
    return firstValueFrom(
      paymentRequestsApi.getItems({}, servicesConfig).pipe(
        tap((transactions: PaymentRequest[]): void => {
          commit('setItems', transactions);
        }),
        runInAnyCase((): void => {
          commit('setLoadingState', false);
        })
      )
    );
  }
};
