
import { defineComponent, PropType } from 'vue';
import DataTable from '@/app/components/particles/DataTable.vue';
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, numeric, decimal, minValue } from '@vuelidate/validators';
import { getColumnsConfig } from '@/app/views/main/invoices/partials/columns.config';
import { IColumnConfig } from '@/app/typings/interfaces/column-config.interface';
import { IInvoiceProduct } from '@/app/typings/interfaces/api-requests-params/create-invoice-request.interface';

export default defineComponent({
  name: 'FormTable',
  components: { BaseInput, DataTable },
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    modelValue: Array as PropType<IInvoiceProduct[]>
  },
  watch: {
    modelValue(value: IInvoiceProduct[]) {
      this.items = value;
    }
  },
  data() {
    const baseColClasses = 'max-lg:block lg:border-y lg:text-center';
    return {
      items: this.modelValue ?? [],
      columns: getColumnsConfig().map((item: IColumnConfig): IColumnConfig => {
        item.classes =
          item.name === 'actions' ? `${baseColClasses} px-4 lg:text-center max-lg:absolute max-lg:right-2 max-lg:top-2` : baseColClasses;

        return item;
      })
    };
  },
  validations() {
    return {
      items: {
        $each: helpers.forEach({
          date: { required },
          title: { required },
          description: {},
          quantity: { required, numeric, minValue: minValue(1) },
          rate: { required, decimal, minValue: minValue(0.01) },
          amount: {}
        })
      }
    };
  },
  emits: {
    ['update:modelValue']: (value: IInvoiceProduct[]): boolean => Boolean(value)
  },
  mounted(): void {
    this.addItem();
  },
  computed: {
    isRemoveDisabled(): boolean {
      return this.modelValue?.length === 1;
    }
  },
  methods: {
    removeItem(item: IInvoiceProduct, index: number): void {
      this.$emit(
        'update:modelValue',
        this.modelValue.filter((item: IInvoiceProduct, i: number): boolean => i !== index)
      );
    },
    async addItem(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }

      const item: IInvoiceProduct = {
        date: new Date().toISOString().slice(0, 10),
        title: null,
        description: null,
        quantity: null,
        rate: null,
        amount: null
      };

      Object.defineProperty(item, 'amount', {
        get(): number {
          return this.rate * this.quantity;
        }
      });

      this.$emit('update:modelValue', [...this.modelValue, item]);
      this.v$.$reset();
    }
  }
});
