
import { defineComponent, PropType } from 'vue';
import { Validation } from '@vuelidate/core';
import LoaderComponent from '@/app/components/particles/Loader.vue';

interface IData {
  input: HTMLInputElement;
  isDragActive: boolean;
}

export default defineComponent({
  name: 'BaseFileUpload',
  components: {
    LoaderComponent
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    label: String,
    hint: String,
    modelValue: {
      type: Array as PropType<File[]>,
      default: (): File[] => []
    },
    validation: Object as PropType<Validation>,
    hideRequiredMark: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/jpeg, image/gif, image/png'
    },
    title: {
      type: String,
      default: 'Upload file'
    },
    description: {
      type: String,
      default: 'Browse files or drag files here'
    }
  },
  data(): IData {
    return {
      input: null,
      isDragActive: false
    };
  },
  emits: {
    ['update:modelValue']: (value: File[]): boolean => Boolean(value)
  },
  computed: {
    isRequired(): boolean {
      return !this.hideRequiredMark && Boolean(this.validation && 'required' in this.validation);
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(value: File[]) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    getFileUrl(file: File): string {
      return URL.createObjectURL(file);
    },
    toggleUpload(): void {
      this.input?.click();
    },
    onChange(): void {
      this.model = Array.from(this.input?.files);
    },
    onDragover(event: DragEvent): void {
      event.preventDefault();
      this.isDragActive = true;
    },
    onDragleave(event: DragEvent): void {
      event.preventDefault();
      this.isDragActive = false;
    },
    onDrop(event: DragEvent): void {
      event.preventDefault();
      this.isDragActive = false;
      const droppedFilesArray: File[] = Array.from(event?.dataTransfer?.files ?? []);
      const dataTransfer: DataTransfer = new DataTransfer();

      for (const file of droppedFilesArray) {
        dataTransfer.items.add(file);

        if (!this.multiple) {
          break;
        }
      }

      this.input.files = dataTransfer.files;
      this.onChange();
    }
  },
  mounted(): void {
    this.input = this.$refs?.input as HTMLInputElement;
  }
});
