import { Exclude, Expose, Transform } from 'class-transformer';
import { transformToDate } from '@/app/misc/helpers/model-conversion/transform-helpers/transform-to-date.function';

@Exclude()
export abstract class AbstractModel {
  @Expose()
  abstract id: number | string;
  @Expose({ name: 'created_at' })
  @Transform(transformToDate)
  createdAt: Date;
  @Expose({ name: 'updated_at' })
  @Transform(transformToDate)
  updatedAt: Date;
  @Expose()
  [Symbol.toPrimitive](hint: 'number' | 'string' | 'default'): string | number | null {
    switch (hint) {
      case 'string':
        return typeof this.id === 'number' ? String(this.id) : this.id;
      case 'number':
        return typeof this.id === 'string' ? Number(this.id) : this.id;
      case 'default':
      default:
        return null;
    }
  }
}
