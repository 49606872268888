import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { ITransactionsState } from '@/app/typings/interfaces/store/transactions-state.interface';
import { transactionsApi } from '@/app/services/api/transactions-api.service';
import { Transaction } from '@/app/typings/models/transaction.model';
import { runInAnyCase } from '@/app/misc/helpers/rxjs-operators/run-in-any-case.operator';
import { IServicesConfig } from '@/app/typings/interfaces/services-config.interface';
import { tap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

export const transactionsActions: ActionTree<ITransactionsState, IRootState> = {
  getItems({ commit }: ActionContext<ITransactionsState, IRootState>, servicesConfig: IServicesConfig): Promise<Transaction[]> {
    commit('setLoadingState', true);
    return firstValueFrom(
      transactionsApi.getItems({}, servicesConfig).pipe(
        tap((transactions: Transaction[]): void => {
          commit('setItems', transactions);
        }),
        runInAnyCase((): void => {
          commit('setLoadingState', false);
        })
      )
    );
  }
};
