import { helpers, not } from '@vuelidate/validators';

export const password = helpers.withMessage(
  'The password must consist of letters (uppercase and lowercase), numbers and symbols',
  helpers.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,30}$/iu)
);
export const phone = helpers.withMessage('Value is not a valid phone number', helpers.regex(/^\+\d{11}$/iu));
export const notFalse = helpers.withMessage(
  'Value is required',
  not(value => value === false)
);
