
import { defineComponent } from 'vue';
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import Modal from '@/app/components/modals/Modal.vue';
import useVuelidate from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';
import { password } from '@/app/misc/validators';
import { useModal } from '@/app/misc/composables/useModal';

export default defineComponent({
  name: 'ChangePasswordModal',
  components: { Modal, BaseInput },
  setup() {
    const { isOpen, toggleModal } = useModal();
    return { isOpen, toggleModal, v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        current_password: '',
        new_password: '',
        repeat_password: ''
      }
    };
  },
  validations() {
    return {
      form: {
        current_password: {
          required
        },
        new_password: {
          required,
          password
        },
        repeat_password: {
          required,
          sameAs: sameAs(this.form.new_password)
        }
      }
    };
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }
      this.$store.dispatch('account/update', this.form).then((): void => {
        this.$emit('submitted');
        this.toggleModal(false);
      });
    }
  }
});
