import { Module } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { IPaymentRequestsState } from '@/app/typings/interfaces/store/payment-requests-state.interface';
import { paymentRequestsState } from '@/app/store/payment-requests/payment-requests.state';
import { paymentRequestsActions } from '@/app/store/payment-requests/payment-requests.actions';
import { paymentRequestsGetters } from '@/app/store/payment-requests/payment-requests.getters';
import { paymentRequestsMutations } from '@/app/store/payment-requests/payment-requests.mutations';

export const paymentRequestsModule: Module<IPaymentRequestsState, IRootState> = {
  namespaced: true,
  state: paymentRequestsState,
  actions: paymentRequestsActions,
  getters: paymentRequestsGetters,
  mutations: paymentRequestsMutations
};
