
import { defineComponent } from 'vue';
import { PaymentRequest } from '@/app/typings/models/payment-request.model';
import { getCryptoIconPath } from '@/app/misc/helpers/getters/get-crypto-icon-path.helper';
import { dateFormat } from '@/app/misc/helpers/date-formatter.helper';
import Modal from '@/app/components/modals/Modal.vue';
import { useModal } from '@/app/misc/composables/useModal';
import { numberFormat } from '@/app/misc/helpers/number-formatter.helper';

export default defineComponent({
  name: 'PaymentRequestItem',
  components: {
    Modal
  },
  props: {
    paymentRequest: {
      type: PaymentRequest
    }
  },
  setup() {
    return useModal();
  },
  data() {
    return {
      modalTitle: 'Pay',
      modalDescription: 'Lorem ipsum solor sit amet',
      size: 'xl'
    };
  },
  computed: {
    currencyIcon(): string {
      return getCryptoIconPath(this.paymentRequest.currency);
    }
  },
  methods: {
    dateFormat,
    numberFormat
  }
});
