import { IDateGroup } from '@/app/typings/interfaces/date-map.interface';
import { AbstractModel } from '@/app/typings/models/_base.model';
import { IRange } from '@/app/typings/interfaces/range.interface';

export function dateGroupByDateRangeFilter<T extends AbstractModel>(range: IRange): (group: IDateGroup<T>) => boolean {
  return ({ date }: IDateGroup<T>): boolean => {
    const isDateLaterThanFrom: boolean = range?.from ? date >= range.from : true;
    const isDateEarlierThanTo: boolean = range?.to ? date <= range.to : true;

    return isDateLaterThanFrom && isDateEarlierThanTo;
  };
}
