import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col grow" }
const _hoisted_2 = { class: "relative grow flex flex-col gap-6 md:overflow-y-auto" }
const _hoisted_3 = { class: "mb-4 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filtered_subtotal = _resolveComponent("filtered-subtotal")!
  const _component_filters_component = _resolveComponent("filters-component")!
  const _component_payment_request_item = _resolveComponent("payment-request-item")!
  const _component_loader_component = _resolveComponent("loader-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_filters_component, { onDateFilter: _ctx.onDateFilter }, {
      default: _withCtx(() => [
        _createVNode(_component_filtered_subtotal, {
          "crypto-coins": _ctx.totalCryptoCoins,
          "fiat-money": _ctx.totalFiatMoney
        }, null, 8, ["crypto-coins", "fiat-money"])
      ]),
      _: 1
    }, 8, ["onDateFilter"]),
    _createElementVNode("ul", _hoisted_2, [
      _createVNode(_component_loader_component, {
        "is-loading": !_ctx.dateGroupedItems?.length && _ctx.isLoading
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (day, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "box"
            }, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.dateFormat(day.date)), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day.items, (item, innerIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: innerIndex,
                  class: "group block pb-4 mb-4 border-b border-b-gray last:border-0 last:pb-0 last:mb-0"
                }, [
                  _createVNode(_component_payment_request_item, { "payment-request": item }, null, 8, ["payment-request"])
                ]))
              }), 128))
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["is-loading"])
    ])
  ]))
}