import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h6", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ]))
}