
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import { defineComponent } from 'vue';
import { auth } from '@/app/services/auth.service';
import { Account } from '@/app/typings/models/account.model';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import ChangePasswordModal from '@/app/components/modals/variants/ChangePasswordModal.vue';
import RegenerateApiKeyModal from '@/app/components/modals/variants/RegenerateApiKeyModal.vue';
import ConnectHandCashModal from '@/app/components/modals/variants/ConnectHandCashModal.vue';
import BaseFileUpload from '@/app/components/particles/forms/BaseFileUpload.vue';
import { imagesApi } from '@/app/services/api/images-api.service';
import { from, mergeMap, Observable, of } from 'rxjs';
import { Image } from '@/app/typings/models/image.model';
import { IUpdateAccountBody } from '@/app/typings/interfaces/api-requests-params/update-account-request.interface';

export default defineComponent({
  name: 'AccountView',
  components: {
    ConnectHandCashModal,
    BaseFileUpload,
    RegenerateApiKeyModal,
    ChangePasswordModal,
    BaseInput
  },
  setup() {
    return { v$: useVuelidate() };
  },
  async mounted(): Promise<void> {
    const { image }: Account = this.$store.getters['account/me'];

    if (image) {
      this.isFileLoading = true;
      const file: File = await image?.getFile();
      this.files = file ? [file] : [];
      this.isFileLoading = false;
    }
  },
  data() {
    const { email, companyName, phone, image }: Account = this.$store.getters['account/me'];

    return {
      isFileLoading: false,
      form: {
        email: email,
        company_name: companyName,
        phone: phone
      },
      files: image?.file ? [image.file] : []
    };
  },
  validations() {
    return {
      form: {
        email: { email, required },
        company_name: {},
        phone: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      me: 'account/me'
    })
  },
  methods: {
    async onMainSubmit(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }
    },
    logout(): void {
      auth.logout().subscribe(() => {
        this.$router.push('/auth/login');
      });
    },
    async updateAccount(): Promise<void> {
      const updateLogoRequest$: Observable<Image> = this.files?.length ? imagesApi.upload(this.files[0]) : of(null);

      updateLogoRequest$
        .pipe(
          mergeMap((image: Image): Observable<Account> => {
            const body: IUpdateAccountBody = {
              ...this.form
            };

            if (image?.id) {
              body.image_key = image.id;
            }

            return from<Promise<Account>>(this.$store.dispatch('account/update', body));
          })
        )
        .subscribe((): void => {
          this.$emit('submitted');
        });
    }
  }
});
