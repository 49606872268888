import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-4 flex justify-between items-center max-md:flex-col max-md:items-start" }
const _hoisted_2 = { class: "max-md:order-2 max-md:mt-4" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "button max-md:order-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "is-open": _ctx.shouldOpen && _ctx.isOpen,
    title: "Enter 2FA Code",
    description: _ctx.modalDescription,
    size: "auto"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onSubmit()), ["prevent"])),
        autocomplete: "off"
      }, [
        _createVNode(_component_base_input, {
          modelValue: _ctx.code,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
          placeholder: "Enter 2FA Code here",
          validation: _ctx.v$.code
        }, null, 8, ["modelValue", "validation"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, [
            _createTextVNode(" Didn't receive a code? "),
            _createElementVNode("button", {
              class: _normalizeClass(["ml-2 font-bold", _ctx.isButtonDisabled ? 'text-gray-500' : 'text-green hover:text-green-500']),
              disabled: _ctx.isButtonDisabled,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendCode().subscribe()))
            }, _toDisplayString(_ctx.isButtonDisabled ? _ctx.time : 'Resend'), 11, _hoisted_3)
          ]),
          _createElementVNode("button", _hoisted_4, [
            _createTextVNode(" Submit "),
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-right" })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-open", "description"]))
}