
import { defineComponent } from 'vue';
import { Transaction } from '@/app/typings/models/transaction.model';
import { getCryptoIconPath } from '@/app/misc/helpers/getters/get-crypto-icon-path.helper';
import { dateFormat } from '@/app/misc/helpers/date-formatter.helper';
import { numberFormat } from '@/app/misc/helpers/number-formatter.helper';

export default defineComponent({
  name: 'TransactionItem',
  props: {
    transaction: {
      type: Transaction
    }
  },
  computed: {
    currencyIcon(): string {
      return getCryptoIconPath(this.transaction.currency);
    }
  },
  methods: {
    dateFormat,
    numberFormat
  }
});
