import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { store } from '@/app/store/store';
import { Transaction } from '@/app/typings/models/transaction.model';

export async function transactionDetailsResolver(
  { params, meta }: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  const storeTransactions: Transaction[] = store.getters['transactions/items'];
  const transactions: Transaction[] = storeTransactions?.length ? storeTransactions : await store.dispatch('transactions/getItems');
  const transaction: Transaction = transactions.find(({ id }: Transaction) => params.transactionId === String(id));
  store.commit('transactions/setCurrent', transaction);
  meta['transaction'] = transaction;
  next();
}
