
import Steps from '@/app/components/particles/Steps.vue';
import AccountCreate from '@/app/views/auth/sign-up/steps/AccountCreate.vue';
import AccountConnect from '@/app/views/auth/sign-up/steps/AccountConnect.vue';
import AccountHandcash from '@/app/views/auth/sign-up/steps/AccountHandcash.vue';
import { defineComponent } from 'vue';
import { SignUpStep } from '@/app/typings/enums/sign-up-step.enum';
import { Account } from '@/app/typings/models/account.model';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'SignUpView',
  computed: {
    SignUpStep() {
      return SignUpStep;
    }
  },
  components: {
    Steps,
    AccountCreate,
    AccountConnect,
    AccountHandcash
  },
  props: {
    isUsedInModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      steps: [
        {
          title: 'Sign up',
          isHidden: this.isUsedInModal,
          isActive: !this.isUsedInModal,
          isCompleted: false
        },
        {
          title: 'Connect',
          isActive: this.isUsedInModal,
          isCompleted: false
        },
        {
          title: 'Handcash',
          isActive: false,
          isCompleted: false
        }
      ],
      hasHandcashAccount: false
    };
  },
  methods: {
    onCodeSubmitted() {
      this.steps[SignUpStep.create].isActive = false;
      this.steps[SignUpStep.create].isCompleted = true;
      this.steps[SignUpStep.connect].isActive = true;
    },
    submittedAnswer(hasHandcash: boolean) {
      this.hasHandcashAccount = hasHandcash;
      this.steps[SignUpStep.connect].isActive = false;
      this.steps[SignUpStep.connect].isCompleted = true;
      this.steps[SignUpStep.handcash].isActive = true;
    },
    resetConnect() {
      this.steps[SignUpStep.connect].isActive = true;
      this.steps[SignUpStep.connect].isCompleted = false;
      this.steps[SignUpStep.handcash].isActive = false;
    }
  }
});
