import { delayWhen, Observable, of, switchMap, timer } from 'rxjs';
import { ClassConstructor } from 'class-transformer';
import { http, HttpService } from '@/app/services/http.service';
import { toModel } from '@/app/misc/helpers/rxjs-operators/to-model.operator';
import { toModelsArray } from '@/app/misc/helpers/rxjs-operators/to-models-array.operator';
import { AbstractModel } from '@/app/typings/models/_base.model';
import { IParams } from '@/app/typings/interfaces/params.interface';
import { IAppConfig } from '@/app/typings/interfaces/app-config.interface';
import { IServicesConfig } from '@/app/typings/interfaces/services-config.interface';
import { store } from '@/app/store/store';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export abstract class AbstractApiBaseService<T extends AbstractModel> {
  protected _http: HttpService = http;
  protected _URLParams: string[] = [];
  protected readonly _AWAITER$: Observable<boolean>;
  protected abstract readonly _MODEL: ClassConstructor<T>;
  protected abstract readonly _URL_PATH: string;

  get baseUrl(): string {
    return this._config.apiUrl;
  }

  get url(): string {
    return this.baseUrl + this._composeUrlPath();
  }

  private get _config(): IAppConfig {
    return store.getters['config'];
  }

  constructor() {
    this._AWAITER$ = timer(0, 0).pipe(
      map((): boolean => Boolean(this._config)),
      filter((value: boolean): boolean => value),
      distinctUntilChanged()
    );
  }

  getItems(params?: IParams, servicesConfig?: IServicesConfig): Observable<T[]> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<T[]> => this._http.get(this.url, { params }, servicesConfig).pipe(toModelsArray(this._MODEL)))
    );
  }

  getItem(id: string, params?: IParams, servicesConfig?: IServicesConfig): Observable<T> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<T> => this._http.get(this.url, { params: { ...params, id } }, servicesConfig).pipe(toModel(this._MODEL)))
    );
  }

  createItem<D>(data: D, servicesConfig?: IServicesConfig): Observable<T> {
    const body: D = { ...data };
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<T> => this._http.post(this.url, body, {}, servicesConfig).pipe(toModel(this._MODEL)))
    );
  }

  updateItem(data: Partial<T>, servicesConfig?: IServicesConfig): Observable<T> {
    const body: Partial<T> = { ...data };
    delete body.id;
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<T> => this._http.patch(`${this.url}/${data.id}`, body, {}, servicesConfig).pipe(toModel(this._MODEL)))
    );
  }

  deleteItem(id: string, servicesConfig?: IServicesConfig): Observable<void> {
    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<void> => this._http.delete(`${this.url}/${id}`, {}, servicesConfig))
    );
  }

  private _composeUrlPath(): string {
    let URLPath: string = this._URL_PATH;

    if (this._URLParams?.length) {
      const params: string[] = URLPath.match(/:[a-z]+(?=\/)?/gi);
      params.forEach((param: string, i: number): void => {
        URLPath = URLPath.replace(param, this._URLParams[i]);
      });
    }

    return URLPath;
  }
}
