
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, sameAs, email, minLength } from '@vuelidate/validators';
import { notFalse, password, phone } from '@/app/misc/validators';
import BaseCheckbox from '@/app/components/particles/forms/BaseCheckbox.vue';
import UserAgreement from '@/app/components/particles/UserAgreement.vue';

export default defineComponent({
  name: 'AccountCreate',
  components: {
    UserAgreement,
    BaseCheckbox,
    BaseInput
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isTermsAccepted: false,
      form: {
        first_name: '',
        last_name: '',
        company_name: '',
        mobile_phone: '',
        email: '',
        password: '',
        confirm_password: ''
      }
    };
  },
  validations() {
    return {
      isTermsAccepted: {
        required,
        notFalse
      },
      form: {
        first_name: {
          required
        },
        last_name: {
          required
        },
        company_name: {},
        mobile_phone: {
          required,
          phone
        },
        email: {
          required,
          email
        },
        password: {
          required,
          password,
          minLength: minLength(6)
        },
        confirm_password: {
          required,
          sameAs: sameAs(this.form.password)
        }
      }
    };
  },
  emits: {
    submitted(): boolean {
      return true;
    }
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }

      this.$store.dispatch('account/signUp', this.form).then((): void => {
        this.$emit('submitted');
      });
    }
  }
});
