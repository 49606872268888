import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sign_up_view = _resolveComponent("sign-up-view")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "is-open": _ctx.isOpen,
    size: "fit",
    onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleModal(false)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_sign_up_view, {
        "is-used-in-modal": true,
        onSubmitted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleModal(false)))
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}