
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import { defineComponent } from 'vue';
import { auth } from '@/app/services/auth.service';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default defineComponent({
  name: 'LogInView',
  components: { BaseInput },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: '',
      password: ''
    };
  },
  validations() {
    return {
      email: {
        email,
        required
      },
      password: {
        required
      }
    };
  },
  beforeMount() {
    if (this.isAuthenticated) {
      this.$router.push('/');
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'account/isAuthenticated'
    })
  },
  methods: {
    async login(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }

      auth
        .login({
          email: this.email,
          password: this.password
        })
        .subscribe((): void => {
          this.$router.push('/');
        });
    }
  }
});
