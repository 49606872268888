import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-726d0ddf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass(["w-full", [_ctx.isResponsive ? 'responsive' : '', _ctx.classes]])
  }, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", {
        class: _normalizeClass(_ctx.headRowClasses)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (config, index) => {
          return (_openBlock(), _createElementBlock("th", {
            key: `${config.name}-${index}`,
            class: "md:border-y p-2"
          }, _toDisplayString(config.title), 1))
        }), 128))
      ], 2)
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("tr", {
          class: _normalizeClass(_ctx.rowClasses),
          key: `${item}-${index}`
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keysToShow, (key, keyIndex) => {
            return (_openBlock(), _createElementBlock("td", {
              key: `${key}-${keyIndex}`,
              "data-label": _ctx.columns?.[keyIndex]?.title,
              class: _normalizeClass(["p-2", _ctx.columns?.[keyIndex]?.classes])
            }, [
              (_ctx.columns?.[keyIndex]?.input)
                ? (_openBlock(), _createBlock(_component_base_input, _mergeProps({
                    key: 0,
                    modelValue: item[key],
                    "onUpdate:modelValue": ($event: any) => ((item[key]) = $event)
                  }, _ctx.columns?.[keyIndex]?.input, {
                    "floating-errors": true,
                    placeholder: _ctx.columns?.[keyIndex]?.title,
                    validation: _ctx.validation,
                    "validation-errors-path": `$each.$response.$errors[${index}].${key}`
                  }), null, 16, ["modelValue", "onUpdate:modelValue", "placeholder", "validation", "validation-errors-path"]))
                : _renderSlot(_ctx.$slots, key, {
                    key: 1,
                    item: item,
                    value: item[key],
                    column: _ctx.columns?.[keyIndex],
                    index: index
                  }, () => [
                    _createTextVNode(_toDisplayString(item[key]), 1)
                  ], true)
            ], 10, _hoisted_1))
          }), 128)),
          _renderSlot(_ctx.$slots, "row-actions", {}, undefined, true)
        ], 2))
      }), 128))
    ]),
    _renderSlot(_ctx.$slots, "tfoot", {}, undefined, true)
  ], 2))
}