
import { defineComponent, PropType } from 'vue';
import { IStep } from '@/app/typings/interfaces/step.interface';

export default defineComponent({
  name: 'StepsComponent',
  props: {
    steps: Array as PropType<IStep[]>
  },
  computed: {
    filteredSteps() {
      return this.steps.filter(({ isHidden }: IStep): boolean => !isHidden);
    }
  }
});
