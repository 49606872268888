import { GetterTree } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { IAccountState } from '@/app/typings/interfaces/store/account-state.interface';

export const accountGetters: GetterTree<IAccountState, IRootState> = {
  me({ me }: IAccountState) {
    return me;
  },
  accountId({ accountId }: IAccountState) {
    return accountId;
  },
  token({ token }: IAccountState): string {
    return token;
  },
  apiKey({ apiKey }: IAccountState): string {
    return apiKey;
  },
  isAuthenticated({ token, accountId }: IAccountState): boolean {
    return Boolean(token && accountId);
  }
};
