
import { defineComponent, PropType } from 'vue';
import { IColumnConfig } from '@/app/typings/interfaces/column-config.interface';
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import { Validation } from '@vuelidate/core';

export default defineComponent({
  name: 'DataTable',
  components: { BaseInput },
  props: {
    items: Array as PropType<any[]>,
    columns: Array as PropType<IColumnConfig[]>,
    isResponsive: Boolean,
    classes: String,
    rowClasses: String,
    headRowClasses: String,
    validation: Object as PropType<Validation>
  },
  computed: {
    keysToShow(): string[] {
      return this.columns?.map(({ name }: IColumnConfig): string => name);
    }
  }
});
