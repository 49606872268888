import { Exclude, Expose, Transform, TransformFnParams } from 'class-transformer';
import { AbstractModel } from '@/app/typings/models/_base.model';
import { transformToDate } from '@/app/misc/helpers/model-conversion/transform-helpers/transform-to-date.function';

@Exclude()
export class PaymentRequest extends AbstractModel {
  @Expose() id: string;
  @Expose({ name: 'account_id' }) accountId: number;
  @Expose() currency: string;
  @Expose({ name: 'customer_name' }) customerName: string;
  @Expose({ name: 'customer_email' }) customerEmail: string;
  @Expose({ name: 'customer_phone' }) customerPhone: string;
  @Expose({ name: 'customer_address' }) customerAddress: string;
  @Expose({ name: 'expires_at' }) @Transform(transformToDate) expiresAt: string;
  @Expose({ name: 'fee_usd' }) feeUsd: number;
  @Expose({ name: 'handcash_id' }) handcashId: string;
  @Expose() paid: boolean;
  @Expose({ name: 'payment_url' }) paymentUrl: string;
  @Expose({ name: 'qr_code' }) qrCode: string;
  @Expose({ name: 'sale_amount_usd' }) saleAmountUsd: string;
  @Expose({ name: 'amount_usd' }) amountUsd: number;

  @Expose()
  @Transform(({ obj }: TransformFnParams): number => obj.amount_usd - obj.fee_usd)
  netSale: number;
}
