import { AbstractApiBaseService } from '@/app/misc/abstract/api-service.abstract';
import { Image } from '@/app/typings/models/image.model';
import { ClassConstructor } from 'class-transformer';
import { IServicesConfig } from '@/app/typings/interfaces/services-config.interface';
import { delayWhen, Observable, of, switchMap } from 'rxjs';
import { toModel } from '@/app/misc/helpers/rxjs-operators/to-model.operator';

export class ImagesApiService extends AbstractApiBaseService<Image> {
  protected readonly _MODEL: ClassConstructor<Image> = Image;
  protected readonly _URL_PATH: string = '/images';

  upload(file: File, servicesConfig?: IServicesConfig): Observable<Image> {
    const form: FormData = new FormData();

    form.append('image', file);

    return of(null).pipe(
      delayWhen(() => this._AWAITER$),
      switchMap((): Observable<Image> => this._http.post(this.url, form, {}, servicesConfig).pipe(toModel(Image)))
    );
  }
}

export const imagesApi: ImagesApiService = new ImagesApiService();
