import { MutationTree } from 'vuex';
import { ITransactionsState } from '@/app/typings/interfaces/store/transactions-state.interface';
import { Transaction } from '@/app/typings/models/transaction.model';
import { transactionsState } from '@/app/store/transactions/transactions.state';

export const transactionsMutations: MutationTree<ITransactionsState> = {
  setItems(state: ITransactionsState, items: Transaction[]): void {
    state.items = items;
  },

  setCurrent(state: ITransactionsState, item: Transaction): void {
    state.current = item;
  },

  setLoadingState(state: ITransactionsState, isLoading: boolean): void {
    state.isLoading = isLoading;
  },

  cleanState(state: ITransactionsState): void {
    Object.entries(transactionsState()).forEach(([key, value]: [string, any]): void => {
      state[key as keyof ITransactionsState] = value;
    });
  }
};
