import { AbstractApiBaseService } from '@/app/misc/abstract/api-service.abstract';
import { Invoice } from '@/app/typings/models/invoice.model';
import { ClassConstructor } from 'class-transformer';

export class InvoiceApiService extends AbstractApiBaseService<Invoice> {
  protected readonly _MODEL: ClassConstructor<Invoice> = Invoice;
  protected readonly _URL_PATH: string = '/invoices';
}

export const invoicesApi: InvoiceApiService = new InvoiceApiService();
