
import { defineComponent } from 'vue';
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import BaseRange from '@/app/components/particles/forms/BaseRange.vue';
import { IRange } from '@/app/typings/interfaces/range.interface';

interface IData {
  filterTotalCrypto: boolean;
  range: IRange;
}

export default defineComponent({
  name: 'FiltersComponent',
  components: { BaseRange, BaseInput },
  emits: {
    dateFilter: (range: IRange): boolean => Boolean(range)
  },
  props: {
    withoutSearch: {
      type: Boolean,
      default: false
    }
  },
  data(): IData {
    return {
      filterTotalCrypto: true,
      range: {
        from: null,
        to: null
      }
    };
  },
  methods: {
    filterByDate() {
      this.$emit('dateFilter', this.range);
    },
    clearFilter() {
      this.range = { from: null, to: null };
      this.$emit('dateFilter', this.range);
    }
  },
  computed: {
    isFiltered(): boolean {
      return Boolean(this.range.from || this.range.to);
    }
  }
});
