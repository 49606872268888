import { IDateGroup } from '@/app/typings/interfaces/date-map.interface';
import { AbstractModel } from '@/app/typings/models/_base.model';

export function groupItemsByDate<T extends AbstractModel>(items: T[], sortDirection: 'asc' | 'desc'): IDateGroup<T>[] {
  const map: { [key: string]: IDateGroup<T> } = {};
  for (const item of items) {
    const day = item.createdAt.toISOString().slice(0, 10);
    map[day] = map[day] || { date: day, items: [] };
    map[day].items.push(item);
  }
  return Object.keys(map)
    .sort(sortByDate(sortDirection))
    .map(day => map[day]);
}

function sortByDate(direction: 'asc' | 'desc'): (a: string, b: string) => number {
  return (a: string, b: string): number => {
    switch (direction) {
      case 'asc':
        return Number(new Date(a)) - Number(new Date(b));
      case 'desc':
        return Number(new Date(b)) - Number(new Date(a));
    }
  };
}
