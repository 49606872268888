
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import Modal from '@/app/components/modals/Modal.vue';
import IMask from 'imask';
import { PHONE_MASK_OPTIONS } from '@/app/misc/constants';
import { ISMSResponse } from '@/app/typings/interfaces/api-responses/sms-response.interface';
import { required } from '@vuelidate/validators';
import { from, Observable, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import moment, { Moment } from 'moment/moment';
import { useModal } from '@/app/misc/composables/useModal';

interface IData {
  code: string;
  dueTime: Moment;
  time: string;
}

export default defineComponent({
  name: 'TwoFactorAuthModal',
  components: { Modal, BaseInput },
  setup() {
    const { isOpen, toggleModal } = useModal();

    isOpen.value = true;

    return { isOpen, toggleModal, v$: useVuelidate() };
  },
  data(): IData {
    return {
      code: '',
      dueTime: null,
      time: '00:00'
    };
  },
  validations() {
    return {
      code: {
        required
      }
    };
  },
  watch: {
    me() {
      if (this.shouldOpen) {
        this.sendCode().subscribe();
      }
    }
  },
  computed: {
    ...mapGetters({
      me: 'account/me'
    }),
    shouldOpen(): boolean {
      return Boolean(this.me?.phone) && !this.me?.phoneVerified;
    },
    modalDescription(): string {
      const phone: string = IMask.createPipe(PHONE_MASK_OPTIONS)(this.me?.phone ?? '');
      return `An sms message was sent to this phone number ${phone} with the 2FA code.`;
    },
    isButtonDisabled(): boolean {
      return this.time !== '00:00';
    }
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }

      this.verify().then(() => {
        this.toggleModal(false);
      });
    },

    sendCode(): Observable<ISMSResponse> {
      return from<Observable<ISMSResponse>>(this.$store.dispatch('account/smsIssue', { to: this.me.phone })).pipe(
        tap((): void => this.startTimer())
      );
    },

    verify(): Promise<ISMSResponse> {
      return this.$store.dispatch('account/smsVerify', { to: this.me.phone, code: this.code });
    },

    startTimer(): void {
      const SECOND: number = 1000;
      this.dueTime = moment(Date.now() + 120 * SECOND);
      timer(0, 0)
        .pipe(
          map((): number => this.dueTime.diff(moment(), 'seconds')),
          distinctUntilChanged(),
          filter((value: number): boolean => value > -1)
        )
        .subscribe(value => {
          const minutes: string = String(Math.floor(value / 60)).padStart(2, '0');
          const seconds: string = String(value % 60).padStart(2, '0');
          this.time = `${minutes}:${seconds}`;
        });
    }
  }
});
