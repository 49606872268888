import { createApp } from 'vue';
import App from './app/App.vue';
import { store } from '@/app/store/store';
import { router } from '@/app/app-routing';
import { IAppConfig } from '@/app/typings/interfaces/app-config.interface';
import Toast, { ToastInterface, useToast } from 'vue-toastification';
import ToastContent from '@/app/components/particles/ToastContent.vue';

/* Fontawesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faUserSecret,
  faChevronRight,
  faChevronDown,
  faUserGear,
  faArrowLeft,
  faArrowUp,
  faExchange,
  faCheck,
  faSearch,
  faFileExport,
  faTrashAlt,
  faSignIn,
  faFile,
  faFileLines,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faUserSecret,
  faChevronRight,
  faChevronDown,
  faUserGear,
  faArrowLeft,
  faArrowUp,
  faExchange,
  faCheck,
  faSearch,
  faFileExport,
  faTrashAlt,
  faSignIn,
  faFile,
  faFileLines,
  faUpload
);

/* Styles */
import 'vue-toastification/dist/index.css';
import './index.css';

const app = createApp(App);
app.config.unwrapInjectedRef = true;

app.use(store);
app.use(router);
app.use(Toast, {});
app.component('FontAwesomeIcon', FontAwesomeIcon);

fetch('/token.json')
  .then((response: Response): Promise<string> => response.json().catch(onError('find')))
  .then((response: string): IAppConfig => {
    try {
      return JSON.parse(atob(response?.split?.('')?.reverse?.()?.join?.('')));
    } catch (e) {
      throw onError('parse')();
    }
  })
  .then((config: IAppConfig): void => {
    store.commit('setConfig', config);
    app.mount('#app');
  });

export function onError(step: 'find' | 'parse'): () => Error {
  return (): Error => {
    const message: string = `Can't ${step} the token.json file. For details, please, read README.md in the repository.`;
    const toast: ToastInterface = useToast();

    toast.error({
      component: ToastContent,
      props: {
        title: 'Error!',
        message
      }
    });

    return new Error(message);
  };
}
