
import { defineComponent, PropType } from 'vue';
import { ErrorObject, Validation } from '@vuelidate/core';
import get from 'lodash.get';

export default defineComponent({
  name: 'BaseCheckbox',
  props: {
    modelValue: Boolean,
    label: String,
    customValidationMessages: Object as PropType<{ [key: string]: string }>,
    validation: Object as PropType<Validation>,
    validationErrorsPath: {
      type: String,
      default: '$errors'
    },
    hideRequiredMark: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    ['update:modelValue']: (value: boolean): boolean => typeof value === 'boolean'
  },
  computed: {
    shouldShowError(): boolean {
      return Boolean(this.validation?.$invalid && this.validation?.$dirty && this.errorObjects?.length);
    },
    isRequired(): boolean {
      return !this.hideRequiredMark && Boolean(this.validation && 'required' in this.validation);
    },
    errorObjects(): ErrorObject[] {
      return get(this.validation, this.validationErrorsPath);
    },
    model: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    getErrorMessage({ $message, $validator }: ErrorObject) {
      switch ($validator) {
        default:
          return this.customValidationMessages?.[$validator] ?? $message;
      }
    }
  }
});
