
import { defineComponent } from 'vue';

interface IData {
  isTotalInCrypto: boolean;
}

export default defineComponent({
  name: 'FilteredSubtotal',
  data(): IData {
    return {
      isTotalInCrypto: Boolean(this.cryptoCoins)
    };
  },
  props: {
    fiatMoney: String,
    cryptoCoins: String
  },
  methods: {
    toggleTotal(isTotalInCrypto?: boolean): void {
      this.isTotalInCrypto = typeof isTotalInCrypto === 'boolean' ? isTotalInCrypto : !this.isTotalInCrypto;
    }
  }
});
