import { IColumnConfig } from '@/app/typings/interfaces/column-config.interface';

export function getColumnsConfig(): IColumnConfig[] {
  return JSON.parse(
    JSON.stringify([
      { name: 'index', title: '#' },
      { name: 'date', title: 'Date', input: { type: 'date' } },
      { name: 'title', title: 'Product / Service', input: { type: 'text' } },
      { name: 'description', title: 'Description', input: { type: 'text' } },
      { name: 'quantity', title: 'QTY', input: { type: 'number', min: 1 } },
      { name: 'rate', title: 'Rate', input: { type: 'number', prefix: '$', min: 0.01, step: 0.01 } },
      { name: 'amount', title: 'Amount', input: { type: 'number', prefix: '$', min: 0.01, step: 0.01, readonly: true } },
      { name: 'actions', title: '' }
    ])
  );
}
