import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { IAccountState } from '@/app/typings/interfaces/store/account-state.interface';
import { ILoginParams } from '@/app/typings/interfaces/params/login-params.interface';
import { ILoginResponse } from '@/app/typings/interfaces/api-responses/login-response.interface';
import { firstValueFrom, from, Observable } from 'rxjs';
import { Account } from '@/app/typings/models/account.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { accountsApi } from '@/app/services/api/accounts-api.service';
import { http } from '@/app/services/http.service';
import { ICreateAccountBody } from '@/app/typings/interfaces/api-requests-params/create-account-request.interface';
import { ISMSVerifyRequest } from '@/app/typings/interfaces/api-requests-params/sms-verify-request.interface';
import { ISMSResponse } from '@/app/typings/interfaces/api-responses/sms-response.interface';
import { ISMSIssueRequest } from '@/app/typings/interfaces/api-requests-params/sms-issue-request.interface';
import {IUpdateAccountBody} from '@/app/typings/interfaces/api-requests-params/update-account-request.interface';
import {IGenerateApiKeyBody} from '@/app/typings/interfaces/api-requests-params/generate-api-key-request.interface';
import {IGenerateApiKeyResponse} from '@/app/typings/interfaces/api-responses/generate-api-key-response.interface';
import {IGenerateEmailVerifyRequest} from '@/app/typings/interfaces/api-requests-params/generate-email-verify-request.interface';
import {IGenerateEmailVerifyResponse} from '@/app/typings/interfaces/api-responses/generate-email-verify-response.interface';
import {IEmailVerifyCodeRequest} from '@/app/typings/interfaces/api-requests-params/email-verify-code-request.interface';
import {IEmailVerifyResponse} from '@/app/typings/interfaces/api-responses/email-verify-response.interface';
import {ICreateHandcashAccountBody} from '@/app/typings/interfaces/api-requests-params/create-handcash-account-request.interface';
import {ICreateHandcashAccountResponse} from '@/app/typings/interfaces/api-responses/create-handcash-account-response.interface';

export const accountActions: ActionTree<IAccountState, IRootState> = {
  login({ rootState, commit, dispatch }: ActionContext<IAccountState, IRootState>, body: ILoginParams): Promise<Account> {
    return firstValueFrom(
      http.post(`${rootState.config.apiUrl}/login`, body).pipe(
        map(({ bearer_token: token, id }: ILoginResponse): string => {
          if (token) {
            commit('setToken', token);
            commit('setAccountId', id);
          }

          return token;
        }),
        switchMap((): Observable<Account> => from(dispatch('getMe')))
      )
    );
  },

  signUp({ rootState, commit, dispatch }: ActionContext<IAccountState, IRootState>, body: ICreateAccountBody): Promise<Account> {
    return firstValueFrom(
      http.post(`${rootState.config.apiUrl}/accounts`, body).pipe(
        map(({ bearer_token: token, id }: ILoginResponse): string => {
          if (token) {
            commit('setToken', token);
            commit('setAccountId', id);
          }

          return token;
        }),
        switchMap((): Observable<Account> => from(dispatch('getMe')))
      )
    );
  },

  logout({ commit }: ActionContext<IAccountState, IRootState>): void {
    commit('clear');
  },

  getMe({ commit, state }: ActionContext<IAccountState, IRootState>): Promise<Account> {
    return firstValueFrom(
      accountsApi.getItem(state.accountId, {}, { skipErrorNotification: true }).pipe(
        map((account: Account): Account => {
          commit('setMe', account);
          return account;
        })
      )
    );
  },

  update({ rootState, commit, dispatch }: ActionContext<IAccountState, IRootState>, body: IUpdateAccountBody): Promise<Account> {
    return firstValueFrom(
      accountsApi.updateAccount(body).pipe(
        tap((): void => {
          dispatch('getMe');
        })
      )
    );
  },

  generateApiKey({ rootState, commit, dispatch }: ActionContext<IAccountState, IRootState>, body: IGenerateApiKeyBody): Promise<IGenerateApiKeyResponse> {
    return firstValueFrom(
      accountsApi.generateApiKey(body).pipe(
        map((res: IGenerateApiKeyResponse): IGenerateApiKeyResponse => {
          commit('setApiKey', res);
          return res;
        })
      )
    );
  },

  generateEmailVerification({ rootState, commit, dispatch }: ActionContext<IAccountState, IRootState>, body: IGenerateEmailVerifyRequest): Promise<IGenerateEmailVerifyResponse> {
    return firstValueFrom(
      accountsApi.generateEmailVerification(body).pipe(
        map((res: IGenerateEmailVerifyResponse): IGenerateEmailVerifyResponse => {
          commit('setEmailId', res);
          return res;
        })
      )
    );
  },

  verifyEmailCode(context: ActionContext<IAccountState, IRootState>, body: IEmailVerifyCodeRequest): Promise<IEmailVerifyResponse> {
    return firstValueFrom(accountsApi.verifyEmailCode(body));
  },

  createHandcashAccount({ rootState, commit, dispatch }: ActionContext<IAccountState, IRootState>, body: ICreateHandcashAccountBody): Promise<ICreateHandcashAccountResponse> {
    return firstValueFrom(
      accountsApi.createHandcashAccount(body).pipe(
        tap((): void => {
          dispatch('getMe');
        })
      )
    );
  },

  smsIssue(context: ActionContext<IAccountState, IRootState>, body: ISMSIssueRequest): Promise<ISMSResponse> {
    return firstValueFrom(accountsApi.smsIssue(body));
  },

  smsVerify({ dispatch }: ActionContext<IAccountState, IRootState>, body: ISMSVerifyRequest): Promise<ISMSResponse> {
    return firstValueFrom(
      accountsApi.smsVerify(body).pipe(
        tap((): void => {
          dispatch('getMe');
        })
      )
    );
  }
};
