import { BehaviorSubject, map, Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';

export class LoaderService {
  private readonly _SPINNER_QUEUE$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);
  private readonly _BAR_QUEUE$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([]);

  get isSpinnerEnabled$(): Observable<boolean> {
    return this._SPINNER_QUEUE$.asObservable().pipe(
      map(({ length }: boolean[]): boolean => Boolean(length)),
      distinctUntilChanged()
    );
  }

  get isBarEnabled(): Observable<boolean> {
    return this._BAR_QUEUE$.asObservable().pipe(
      map(({ length }: boolean[]): boolean => Boolean(length)),
      distinctUntilChanged()
    );
  }

  constructor() {
    this._SPINNER_QUEUE$
      .asObservable()
      .pipe(
        filter((queue: boolean[]): boolean => queue.length > 0 && Boolean(queue[0])),
        tap((): void => {
          const updatedQueue: boolean[] = this._SPINNER_QUEUE$.value;
          updatedQueue[0] = false;
          this._SPINNER_QUEUE$.next(updatedQueue);
        })
      )
      .subscribe();
    this._BAR_QUEUE$
      .asObservable()
      .pipe(
        filter((queue: boolean[]): boolean => queue.length > 0 && Boolean(queue[0])),
        tap((): void => {
          const updatedQueue: boolean[] = this._BAR_QUEUE$.value;
          updatedQueue[0] = false;
          this._BAR_QUEUE$.next(updatedQueue);
        })
      )
      .subscribe();
  }

  on(showSpinner: boolean): void {
    this._addToQueue(showSpinner);
  }

  off(): void {
    this._removeDismissed();
  }

  private _addToQueue(showSpinner: boolean): void {
    if (showSpinner) {
      this._SPINNER_QUEUE$.next(this._SPINNER_QUEUE$.value.concat([true]));
    }
    this._BAR_QUEUE$.next(this._BAR_QUEUE$.value.concat([true]));
  }

  private _removeDismissed(): void {
    const updatedSpinnerQueue: boolean[] = this._SPINNER_QUEUE$.value;
    const updatedBarQueue: boolean[] = this._SPINNER_QUEUE$.value;

    if (!updatedSpinnerQueue[0] && typeof updatedSpinnerQueue[0] === 'boolean') {
      updatedSpinnerQueue.shift();
    }

    if (!updatedBarQueue[0] && typeof updatedBarQueue[0] === 'boolean') {
      updatedBarQueue.shift();
    }

    this._SPINNER_QUEUE$.next(updatedSpinnerQueue);
    this._BAR_QUEUE$.next(updatedBarQueue);
  }
}

export const loader: LoaderService = new LoaderService();
