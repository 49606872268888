import { IDateGroup } from '@/app/typings/interfaces/date-map.interface';
import { AbstractModel } from '@/app/typings/models/_base.model';

export function getDateGroupsTotalAmount<T extends AbstractModel>(items: IDateGroup<T>[], key: keyof T): number {
  return items.reduce((total: number, { items }: IDateGroup<T>): number => {
    total += items.reduce((subtotal: number, { [key]: value }: T): number => {
      subtotal += typeof value === 'number' ? value : 0;
      return subtotal;
    }, 0);
    return total;
  }, 0);
}
