export function getCryptoIconPath(currency: 'USDC' | 'BSV' | string): string {
  let file: string;

  switch (currency) {
    case 'USDC':
      file = 'icon-crypto-usdc.svg';
      break;
    case 'BSV':
      file = 'icon-crypto-bitcoin-sv.svg';
      break;
    default:
      file = 'icon-crypto-bitcoin.svg';
      break;
  }

  return `/images/${file}`;
}
