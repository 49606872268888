import { Module } from 'vuex';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { ITransactionsState } from '@/app/typings/interfaces/store/transactions-state.interface';
import { transactionsState } from '@/app/store/transactions/transactions.state';
import { transactionsGetters } from '@/app/store/transactions/transactions.getters';
import { transactionsActions } from '@/app/store/transactions/transactions.actions';
import { transactionsMutations } from '@/app/store/transactions/transactions.mutations';

export const transactionsModule: Module<ITransactionsState, IRootState> = {
  namespaced: true,
  state: transactionsState,
  getters: transactionsGetters,
  actions: transactionsActions,
  mutations: transactionsMutations
};
