import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_steps = _resolveComponent("steps")!
  const _component_account_create = _resolveComponent("account-create")!
  const _component_account_connect = _resolveComponent("account-connect")!
  const _component_account_handcash = _resolveComponent("account-handcash")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'container mb-10': !_ctx.isUsedInModal })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative", { 'box p-6 md:p-12': !_ctx.isUsedInModal }])
    }, [
      _createVNode(_component_steps, {
        steps: _ctx.steps,
        class: _normalizeClass(["md:absolute md:right-8 max-md:mb-5", { 'md:top-12': !_ctx.isUsedInModal }])
      }, null, 8, ["steps", "class"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.steps[_ctx.SignUpStep.create].isActive)
          ? (_openBlock(), _createBlock(_component_account_create, {
              key: 0,
              onSubmitted: _ctx.onCodeSubmitted
            }, null, 8, ["onSubmitted"]))
          : _createCommentVNode("", true),
        (_ctx.steps[_ctx.SignUpStep.connect].isActive)
          ? (_openBlock(), _createBlock(_component_account_connect, {
              key: 1,
              onSubmitted: _ctx.submittedAnswer
            }, null, 8, ["onSubmitted"]))
          : _createCommentVNode("", true),
        (_ctx.steps[_ctx.SignUpStep.handcash].isActive)
          ? (_openBlock(), _createBlock(_component_account_handcash, {
              key: 2,
              "is-used-in-modal": _ctx.isUsedInModal,
              hasHandcash: _ctx.hasHandcashAccount,
              onSubmitted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submitted'))),
              onBackToConnect: _ctx.resetConnect
            }, null, 8, ["is-used-in-modal", "hasHandcash", "onBackToConnect"]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 2))
}