import { ActionContext, ActionTree } from 'vuex';
import { IInvoicesState } from '@/app/typings/interfaces/store/invoices-state.interface';
import { IRootState } from '@/app/typings/interfaces/store/root-state.interface';
import { Invoice } from '@/app/typings/models/invoice.model';
import { ICreateInvoiceRequest } from '@/app/typings/interfaces/api-requests-params/create-invoice-request.interface';
import { invoicesApi } from '@/app/services/api/invoice-api.service';
import { firstValueFrom } from 'rxjs';

export const invoicesActions: ActionTree<IInvoicesState, IRootState> = {
  create(context: ActionContext<IInvoicesState, IRootState>, body: ICreateInvoiceRequest): Promise<Invoice> {
    return firstValueFrom(invoicesApi.createItem(body));
  }
};
