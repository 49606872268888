
import BaseInput from '@/app/components/particles/forms/BaseInput.vue';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { phone } from '@/app/misc/validators';
import FormTable from '@/app/views/main/invoices/partials/FormTable.vue';
import BaseTextarea from '@/app/components/particles/forms/BaseTextarea.vue';
import InvoicePreviewModal from '@/app/components/modals/variants/InvoicePreviewModal.vue';
import PreviewTable from '@/app/views/main/invoices/partials/PreviewTable.vue';
import { ICreateInvoiceRequest, IInvoiceProduct } from '@/app/typings/interfaces/api-requests-params/create-invoice-request.interface';
import { Currency } from '@/app/typings/enums/currency.enum';

export type ICreateInvoiceFormData = Omit<ICreateInvoiceRequest, 'customer_name'> & {
  customer_first_name: string;
  customer_last_name: string;
};

interface IData {
  form: ICreateInvoiceFormData;
}

export default defineComponent({
  name: 'InvoiceCreate',
  components: {
    PreviewTable,
    InvoicePreviewModal,
    BaseTextarea,
    FormTable,
    BaseInput
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data(): IData {
    return {
      form: {
        customer_company_name: '',
        customer_email: '',
        customer_address: '',
        customer_first_name: '',
        customer_last_name: '',
        customer_phone: '',
        redirect_url: 'https://2agunshow.com',
        currency: Currency.BSV,
        message: '',
        body: '',
        products: [] as IInvoiceProduct[],
        total_amount: 0
      }
    };
  },
  validations() {
    return {
      form: {
        customer_company_name: {},
        customer_email: { email, required },
        customer_address: {},
        customer_first_name: { required },
        customer_last_name: { required },
        customer_phone: { phone },
        redirect_url: { required },
        currency: { required },
        message: {},
        body: {},
        total_amount: {}
      }
    };
  },
  computed: {
    total(): number {
      return parseFloat(
        this.form.products
          .reduce((acc: number, { rate, quantity }: IInvoiceProduct): number => {
            acc += rate * quantity;
            return acc;
          }, 0)
          .toFixed(2)
      );
    },
    requestBody(): ICreateInvoiceRequest {
      const formData: ICreateInvoiceFormData = JSON.parse(JSON.stringify(this.form));

      return {
        currency: formData.currency,
        redirect_url: formData.redirect_url,
        customer_name: `${formData.customer_first_name ?? ''} ${formData.customer_last_name ?? ''}`.trim(),
        customer_email: formData.customer_email,
        customer_phone: formData.customer_phone,
        customer_address: formData.customer_address,
        customer_company_name: formData.customer_company_name,
        message: formData.message,
        body: formData.body,
        products: formData.products,
        total_amount: this.total
      };
    }
  },
  methods: {
    async onSubmit(): Promise<void> {
      if (!(await this.v$.$validate())) {
        return;
      }

      this.toggleModal(true);
    },
    onAccepted({ body }: Pick<ICreateInvoiceRequest, 'body'>) {
      this.form.body = body;
      this.createInvoice();
    },
    createInvoice() {
      this.$store.dispatch('invoices/create', this.requestBody).then(() => {
        this.toggleModal(false);
        this.$router.push('/payment-requests');
      });
    },
    toggleModal(isOpen?: boolean): void {
      (this.$refs.modal as typeof InvoicePreviewModal).toggleModal(isOpen);
    }
  }
});
